div.searchResult{
    background:transparent;
    position: sticky;
    top: 210px;
    z-index: 3;
    background:  white;
    /*height: 50px; FP: 2024-05-21 raderade denna, för att mc-listan ska visas på mobil */
}

div.searchResult div.searchResultBoxes{
    /*width: calc(100% - 20px);*/
    width: calc(100% - 0px);

    display: flex;
    flex-direction: row;
    min-height:100px;
    background-color: white;
    
    
}

div.searchResult div.searchResultBox{
    background: var(--color-bg);
    width: calc(32% - 36px);
    padding:10px;
    margin:10px;
    border-radius: 10px;
    flex: 1;
    overflow: scroll;
		max-width:33%;
		max-height:300px; /* FP: 2024-05-21 la till denna så att inte resultat mc ska fylla hela skärmen */

}

div.searchResult h3.productResultLabel{
    padding-top:15px;
    padding-left:10px;
    display: inline-block;
    max-width: calc(100% - 230px); /* FP: 2021-11-24 la på denna så att resultatet inte ska peta ned sortering */
}

div.searchResult div.searchResultBox div.searchResultItem, 
div.searchResult div.searchResultBox div.listModell{
    border-radius: 5px;
    margin-bottom: 5px;
    margin-top: 5px;
    padding:5px;
    color:var(--color-font-one-negative);
    background: var(--color-main-button);
    
    cursor: pointer;
    
}

div.searchResult div.searchResultBoxes div.showAll img{
	width:20px;
    height:auto;
    padding-top:10px;
}

div.searchResult div.searchResultBox img.showAllResult{
    width:20px;
    height:auto;
    padding-top:10px;
}

div.searchResult div.searchResultBoxes div.searchResultMc.searchResultBox div.listModell img.showYearButton{
    width:20px;
    margin-right:5px;
    height:auto;
    float:left;
}

div.searchResult div.toolbar{
    background:var(--color-main-button);
    float:right;
    height:40px;
    border-radius: 10px;
    margin-top:5px;
    margin-right:10px;
    text-align:right;
    padding-left:10px;
}

div.searchResult div.sort{
    background:var(--color-main-button);
    float:right;
    
    border-radius: 10px;
    margin-top:5px;
    margin-right:10px;
    text-align:left;
    padding-left:10px;
    min-width: 80px;

    padding-right:10px;
}

div.searchResult div.sort.open{
    border-radius: 10px 10px 0px 0px;
}

div.searchResult div.sort div.sortHeader{
    display: flex;
    height:40px;
    align-items: center;
    
    
}




div.searchResult div.sort div.sortOptionList{
    background:var(--color-main-button);
 
    position:absolute;
    z-index: 3;
    margin-left:-10px;
    /*width: 200px;*/
    color:var(--color-font-one-negative);
    border-radius: 0px 0px 10px 10px;
    padding:10px;
}

div.searchResult div.sort div.sortOptionList div.sortOption.selected,
div.searchResult div.sort div.sortOptionList div.sortOption:hover{
    background: var(--color-main-button-break);
}

div.searchResult div.sort div.sortOptionList div.sortOption{
    background:var(--color-bg-header-topmenu-button);
    margin-bottom:10px;
    border-radius:10px;
    padding:5px;
    cursor: pointer;
    
}
div.searchResult div.sortOptionList div.sortOption{
    display: flex;
    align-items: center;

}

div.searchResult div.sortOptionList div.sortOption img, 
div.searchResult div.sortHeader img{
    width:25px;
    height:25px;
}

div.searchResult div.sortHeader h3,
div.searchResult div.sortOptionList div.sortOption h3{
    color:var(--color-font-one-negative);
    padding-left:5px;
    font-size: calc(0.3vw + 10px);
}

div.searchResult div.sort div.sortHeader div.inStockBadge,
div.searchResult div.sort div.sortHeader div.outletBadge{
    display: block;
}


div.searchResult div.sort div.sortHeader div.inStockBadge h3,
div.searchResult div.sort div.sortHeader div.outletBadge h3{
    
    background: var(--color-main-button-break);
    padding:2px;
    border-radius: 5px;
    padding-left:5px;
    padding-right:5px;
    margin-left:5px;
    
}

div.searchResult div.toolbar img{
    width:25px;
    padding-right:10px;
    padding-top:4px;
    
  }

div.searchResult div.toolbar a.active img,
div.searchResult div.toolbar img.active
{
  width:28px;
  height:28px;
}

div.searchResult div.noResult.desktop{
    padding:10px;
    background: var(--color-bg);
    margin:10px;
    display: inline-block;
    border-radius: 10px;
}

div.searchResult div.searchResultBoxes div.searchResultMc.searchResultBox div.listModell{
    color:var(--color-font-one-negative);
    background: var(--color-main-button);
}

div.searchResult div.searchResultBoxes div.searchResultMc.searchResultBox div.listModell div.yearDivStyleBlock div.mcListYear{
    background: var(--color-main-button-two);
}
div.searchResult h3.productResultLabel div.showAllButton{
    padding:3px;
    padding-left:10px;
    padding-right:10px;
    display: inline-block; 
    background: var(--color-main-button);
    margin-left:0px;
    border-radius: 10px;
    margin-top:5px;
    color:var(--color-font-one-negative);
    font-size: calc(0.3vw + 8px);
}

div div.searchResult div.noResult.desktop div.filterList{
    display: flex;
    flex-direction: row;
    background: transparent;
    padding-top:0px;
    max-height:30px;
}

div div.searchResult div.noResult.desktop div.filterList div.filter{
    display: flex;
    flex-direction: row;
    align-items: center;
    
    max-height:30px;
    cursor: pointer;
}

div div.searchResult div.noResult.desktop div.filterList div.filter img{
   
    height:16px;
    width:16px;
}

div div.searchResult div.noResult.desktop div.filterList div.filter h4{
    font-size: calc(0.4vw + 10px);
}

html body div#root div.App div.layout div div.searchResult div.noResult.desktop{
    
    display: block;

}

html body div#root div.App div.layout div div.searchResult div.noResult.desktop div.filterList div.filter.mc img.selectYear{
    display: none; /* FP: döljer att kunna välja år på filterlistan som visas vid inget resultat */
}

div.searchResult div.outletButton{
			background: var(--color-main-button-break);
			float:right;
			height:40px;
			display: flex;
			align-items:center;
			margin-top:5px;
			margin-right:10px;
			
			border-radius: 10px;
			color: var(--color-font-one-negative);
font-size: calc(0.3vw + 10px);


		}

		div.searchResult div.outletButton h3{
padding-left:10px;
			padding-right:10px;
		}

		div.searchResult div.outletButton.off{
			background: var(--color-main-button);
		}

@media (max-width: 768px) {

    div.searchResult{
        background:transparent;
        position: initial;
        
    }

    div.searchResult div.searchResultBoxes{
        display: block;
        flex-direction: column;
        max-height: none;
    }

    
    div.searchResult div.searchResultBox{
        background: var(--color-bg);
        width: calc(100% - 40px);
        display: block; 
				max-width: none;
			
    }

    div.searchResult h3.productResultLabel{
        padding-top:10px;
        font-size: calc(0.5vw + 10px);
    }

    div.searchResult div.toolbar, 
    div.searchResult div.sort{
        margin-top:0px;
    }

    div.searchResult div.sort{
        margin-right:2px;
        padding:2px;
        height:30px;
        padding-left:5px;
        padding-right:5px;
    }

    div.searchResult div.sort div.sortHeader{
        height:30px;
    }
    
    div.searchResult div.sort div.sortOptionList{
        margin-left:-5px;
    }
    div.searchResult div.toolbar{
        height:33px;
    }

    div.searchResult div.toolbar img,
    div.searchResult div.sortHeader img,
    div.searchResult div.sortOptionList div.sortOption img{
        width:18px;
        height:18px;
    }

    div.searchResult div.toolbar a.active img,
    div.searchResult div.toolbar img.active
    {
    width:22px;
    height:22px;
    }

    div.searchResult h3.productResultLabel div.showAllButton{
        padding:5px;
    }

    html body div#root div.App div.layout div div.searchResult div.noResult.desktop{
        display: none; 
    }

    div.searchResult div.sort div.sortHeader div.inStockBadge,
		div.searchResult div.sort div.sortHeader div.outletBadge,
		div.searchResult div.outletButton{
    display: none;
  
		}
		


}










