div.dropMenuBikeType div.dropMenuContent div.bikeTypeList div.bikeType{
    width:50%;
}

div.bikeTypeList div.bikeType {
    background: var(--color-header-topmenu);
    margin: 10px;
    padding: 10px;
    border-radius: 10px;


}

