div.dropMenuCategory div.categoryTree, div.dropMenuCategory div.categoryList{ 
    background: var(--color-header-topmenu); 
    width: 50%;
    float:left;
 }
 
 div.dropMenuCategory div.categoryBrandGrid{
    background: var(--color-unknown);
    width: 50%;
    float:right;
 }
 
 div.dropMenuCategory div.categoryBrandGrid div.category{
 
    float:left;
    background:var(--color-unknown);
    border-radius: 10px;
    padding:10px;
    margin:10px;
 }
 
div.categoryTree div div.categoryTreeNode{
   background: var(--color-main-button);
   margin:10px;
   padding:10px;
   border-radius: 10px;
}

div.dropMenuCategory div.dropMenuContent div.categoryList div.categoryListNode{
   background: var(--color-main-button);
   padding:10px;
   margin:10px;
   border-radius: 10px;
   color:var(--color-font-one-negative);
}

div#categoryTree.categoryTree div.categoryTreeNode.level_x{ background:var(--color-bg-categoryTreeNode-x); }

div#categoryTree.categoryTree div.categoryTreeNode.level_xx{ background:var(--color-bg-categoryTreeNode-xx); }

div#categoryTree.categoryTree div.categoryTreeNode.level_xxx{ background:var(--color-bg-categoryTreeNode-xxx); }


div.dropMenuCategory div.categoryGrid{
    background: transparent;
    width: 50%;
    float:right;
    position:sticky;
    top:70px;
}

div.dropMenuCategory div.categoryGrid img{
    max-width: 250px;
    width:100%;
    
}




div.dropMenuCategory div.categoryGrid table{
    border-spacing: 10px;
}

div.dropMenuCategory div.categoryGrid table tr td{
    padding: 5px;
    background: var(--color-main-button);
    border-radius: 10px;
    text-align: center;
    height:100px;
    font-size: calc(0.6vw + 8px);
    width:33%;
}


div.dropMenuCategory div.categoryGrid table tr td.selected{
    
    /*background: var(--color-main-button-break);*/
    
}

div.dropMenuCategory div.categoryGrid table tr td b{
    color:white;
    
}