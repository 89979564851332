
div.customer{
  background: var(--color-bg);
  margin:10px;
  padding:10px;
  border-radius: 10px;
  
  margin-bottom:100px;
  
}



div.customer div.toolbar div.buttonCreate{
  background:var(--color-bg-header-topmenu-button);
  padding:10px;
  margin-top:10px;
  margin-right:10px;
  display:inline-block;
  border-radius: 10px;
  
}
div.customer div.toolbar div.buttonCreate.alert{
  background: #d3460e;
}


div.customer div.toolbar div.button a,
 div.customer div.toolbar div.button a:visited,
 div.customer div.toolbar div.button{
  text-decoration: none;
  color: var(--color-font-one-negative);

}

div.customer div div.toolbar div.button{
  background:var(--color-bg-header-topmenu-button);
  padding:10px;
  margin-top:10px;
  margin-right:10px;
  display:inline-block;
  border-radius: 10px;
}

div.customer div div.toolbar div.button,
div.customer div div.toolbar{
  color:var(--color-font-one-negative);
}


div.customer div.company{
  background:var(--color-bg-main);
  margin-top:0px;
  border-radius: 10px;
  padding:10px;
  color: var(--color-font-one);
}

div.customer div.company img.reloadButton{
  float:right;
  width:30px;
  margin-top:8px;
  margin-right:8px;
}

div.customer div div.companyDetails, 
div.customer div div.userDetails{ 
  background:var(--color-bg-main);
  margin-top:10px;
  border-radius: 10px;
  padding:10px;
  margin-bottom:0px;
}

div.customer div div.companyDetails h2,  
div.customer div div.userDetails h2{
  margin:0px;
}

div.customer div div.companyDetails input,  
div.customer div div.userDetails input,
div.customer div div.companyDetails textarea,  
div.customer div div.userDetails textarea{
  font-size: 16px;
  border:0px solid transparent;
  padding:10px;
  border-radius: 5px;
  margin-top:10px;
  margin-bottom:10px;
  width:400px;
  background: var(--color-order-textfield);
  color:var(--color-font-one);
}

div.customer div div.companyDetails button,  
div.customer div div.userDetails button{
  background: var(--color-main-button);
  font-size:16px;
  border:1px solid black;
  color:var(--color-font-one-negative);
  border-radius: 5px;
  padding:10px;
  margin:0px;
  margin-top:5px;
  width:160px;

}


div.customer div.orderList div.order{
  background:var(--color-bg-main);
  margin-top:10px;
  border-radius: 10px;
  padding:10px;
  margin-bottom:40px;
  border:2px solid transparent;
  
}

div.customer div.orderList div.order div.orderInformation{
  background: transparent;
}
div.customer div.orderList div.order div div.orderDetails{
  background: var(--color-bg);
  margin-top:10px;
  border-radius: 10px;
  padding:10px;
  margin-bottom:40px;
}

div.customer div.orderList div.order h2{
  display: inline;
  margin-right:10px;
  font-weight: bold;
}

div.customer div.orderList div.order h2 span.orderDetails{
  color:var(--color-font-two);
}


div.customer div.orderList div.order div.orderAdd{
  background: var(--color-bg);
  padding:10px;
  margin-top:10px;
  border-radius: 10px;
}

div.customer div.orderList div.order div.orderAdd input{
  border:0px solid transparent;
  margin-right:10px;
  padding:10px;
  font-size: 16px;
  margin-top:5px;
  border-radius: 10px;
}

div.customer div.orderList div.order div.orderAdd input.quantity{
  width:50px;
}

div.customer div.orderList div.order div.orderAdd button.orderAddButton{
  display: inline-block;
  background: var(--color-main-button);
  padding:8px;
  color:var(--color-font-one-negative);
  border-radius: 10px;
  border:0px;
  font-size: 16px;
}

div.customer div.orderList div.order div.orderImport{
  background: var(--color-bg);
  padding:10px;
  margin-top:10px;
  border-radius: 10px;
}

div.customer div div.orderList div.order div div.orderImport div.orderImportHeader img{
  width:30px;
  height:30px;
  float:left;
  padding-right:5px;
}

div.customer div div.orderList div.order div div.orderImport div.orderImportHeader{
  height:30px;
}

div.customer div div.orderList div.order div div.orderImport div.importData,
div.customer div div.orderList div.order div div.orderImport div.importResult{
  background: var(--color-bg);
  width:47%;
  
  margin-top:10px;
  font-weight: bold;

}

div div.customer div div.orderList div.order div div.orderImport div div h4{
  margin:0px;
  margin-top:5px;
  margin-bottom:5px;
}

div.customer div div.orderList div.order div div.orderImport div div.importButton {
  border:0px solid transparent;
  background: var(--color-main-button);
  color: var(--color-font-one-negative);
  display: inline-block;
  padding:10px;
  margin-top:10px;
  border-radius: 10px;
  
}

div.customer div div.orderList div.order.selected div div.orderImport div div.importFile{
  background:var(--color-bg-main);
  border-radius: 10px;
  padding:10px;
  margin-top:10px;
}

div.customer div div.orderList div.order.selected div div.orderImport div div.importFile input{
  font-size: 16px;
}

div.customer div div.orderList div.order.selected div div.orderImport div div.importFile table tr td{
  border:1px solid gray;
  padding:5px;
}


div.customer div div.orderList div.order.selected div div.orderImport div div.importFile table{
  border-spacing: 0px;
  border-collapse: separate;
  border:1px solid gray;
}

div.customer div div.orderList div.order div div.orderImport div.importData textarea{
  background: var(--color-bg-main);
  height:200px;
  width:100%;
  border-radius: 10px;
  padding:10px;
  border: 0px solid transparent;
}

div.customer div div.orderList div.order div div.orderImport div div.importResult div.importResultList div.messageOk{
  background: var(--color-message-ok);
}

div.customer div div.orderList div.order div div.orderImport div div.importResult div.importResultList div.messageError{
  background: var(--color-message-alert);
}

div.customer div div.orderList div.order div div.orderImport div div.importResult div.importResultList div{
  margin:5px;
  padding:5px;
  border-radius: 10px;
  color:var(--color-font-one-negative);
}

div.customer div div.orderList div.order div div.orderImport div.importResult div.importResultList{
  background: var(--color-bg-main);
  overflow:scroll;
  height:200px;
  padding:10px;
  border: 0px solid transparent;
  border-radius: 10px;

}


div.customer div div.orderList div.order div div.orderImport div.importData{
  float:left;
}

div.customer div div.orderList div.order div div.orderImport div.importResult{
  float:right;
}

div.customer div.orderList div.order.selected{
  border:2px solid var(--color-order-selected);
}
div.customer div div.orderList div.order div.orderInformation div.addProductsButton{
  background: var(--color-message-warning);
  color:var(--color-font-one-negative);
  
  padding: 10px;
  margin-top:10px;
  border-radius: 10px;
  display:inline-block;
}

div.customer div div.orderList div.order div.orderInformation div.addProductsButton.selected{
  background: var(--color-main-button);
  color:var(--color-font-one-negative);
}

div.customer div div.orderList div.order div div.toolbar div.addProductsWrapper{
  background: var(--color-main-button);
  margin:0px;
  margin-top:5px;
  padding:0px;
  padding-left:10px;
}

div.customer div div.orderList div.order.selected div div.toolbar div.addProductsWrapper div.addProductsButton{
  background: var(--color-main-button-two);
  padding:5px;
  margin:0px;
  margin:5px;
  display: inline-block;
}


div.customer div.orderList div.order div.orderRowList, div.customer div.orderList div.order div.orderSummary{

  background:var(--color-bg);
  margin-top:10px;
  border-radius: 10px;
  padding:10px;
}

div.customer div.orderList div.order div.orderSummary{
  text-align: right;
}  

div.customer div.orderList div.order div.orderSummary h2{
  font-weight:bold;

}


div.customer div.orderList div.order div.orderInformation img{
  width:20px;
  padding-top:8px;
  padding-right:8px;
  float:left;
}


div.customer div.orderList div.order div.orderInformation div.orderStatus{

  padding:10px;
  
  float:right;
  background: var(--color-main-button);
  text-align:right;
  border-radius: 10px;
  font-weight: bold;
  width:160px;
  text-align: center;
  color:var(--color-font-one-negative);

}

div.customer div.orderList div.order div.orderInformation div.orderStatus img{
  width:24px;
  padding-top:0px;
  padding-left:8px;
  float:right;

}

div.customer div div.orderList div.order div.orderInformation div.orderStatus.status0{
  background:var( --color-main-button);
}

div.customer div div.orderList div.order div.orderInformation div.orderStatus.status10{
  background:var( --color-message-warning);
}

div.customer div div.orderList div.order div.orderInformation div.orderStatus.status20{
  background:var( --color-message-ok);
}

div.customer div div.orderList div.order div.orderInformation div.orderStatus.status30{
  background:var( --color-message-ok);
}

div.customer div.orderList div.order div.orderRowList div.orderRow{

  background: var(--color-bg-main);
  margin-top:2px;
  border-radius: 5px;
  padding:5px;
  padding-left:5px;
  color:var(--color-font-one);

}

div.customer div.orderList div.order div.orderRowList div.orderRow div, 
div.customer div div.orderList div.order div.orderListHeader div{
    display: inline-block;
    padding-left:3px;
    padding-right:5px;
    border-radius: 2px;
    margin-right:2px;
    color:var(--color-font-one);
    vertical-align: top;
}

div.customer div div.orderList div div.orderRowList div.orderRow div.price.outlet{
  color:var(--color-font-outlet-negative);
}

div.customer div.orderList div.order  div.artnr{
  width:150px;
  background:var(--color-bg);
  margin-right:10px;
}

div.customer div.orderList div.order  div.product{
  width:calc(100% - 420px);
}

div.customer div.orderList div.order div.orderRowList div.stock{
  width:50px;
  background:green;
  margin-right:0px;
  text-align:right;

}


div.customer div div.orderList div.order div div.orderRowList div.orderRow div.stock.stockNONE{
  background:var(--color-stock-none);
  color: var(--color-font-stock);
}

div.customer div div.orderList div.order div div.orderRowList div.orderRow div.stock.stockLOW{
  background:var(--color-stock-low);
  color: var(--color-font-stock);
}

div.customer div div.orderList div.order div div.orderRowList div.orderRow div.stock.stockOK{
  background:var(--color-stock-ok);
  color: var(--color-font-stock);
}

div.customer div.orderList div.order div.orderRowList div.orderListHeader div.stock{
  background: transparent;
}



div.customer div.orderList div.order div.orderRowList div.quantity{
  width:50px;
  
    text-align:right;
  
}
div.customer div.orderList div.order div.orderRowList div.orderRow div.quantity{
  background:var(--color-bg);
}

div.customer div.orderList div.order div.orderRowList div.orderListHeader div.quantity div{
  background: transparent;
  width:100%;
}



div.layout div div.customer div div.orderList div.order div div.orderRowList div.orderRow div.quantity div.label{
  background:var(--color-bg);
  width:100%;
  text-align:right;
  padding-left: 0px;
  color: var(--color-font-one);
}


div.customer div.orderList div.order div.orderRowList div.orderRow div.quantity div.edit{
  background: var( --color-main-button-two);
  overflow: hidden;
  position:absolute;

  border-radius: 5px;
  text-align: center;
  margin-top:-7px;
  margin-left:-5px;
  
  
}




div.customer div.orderList div.order div.orderRowList div.quantity div.edit input{
  width:120px;
  margin:5px;
  font-size:16px;
  margin-top:5px;
}

div.customer div.orderList div.order div.orderRowList div.quantity div.edit button{
  background: var(--color-main-button);
  font-size:16px;
  border:1px solid black;
  color:var(--color-font-one-negative);
  border-radius: 5px;
  padding:3px;
  margin:5px;

}

div.customer div.orderList div.order div.orderRowList  div.quantity div.edit.editVisible{
  height:78px;
  transition: height 300ms linear 0s;
}

div.customer div.orderList div.order div.orderRowList  div.quantity div.edit.editHidden{
  height:0px;
  transition: height 300ms linear 0s;
}

div.customer div.orderList div.order div.price{
  width:80px;
  background:var(--color-bg);
  margin-right:0px;
  text-align:right;
}

div.customer div.orderList div.order div.buttonDelete{
  width:30px;
  background:transparent;
  margin-right:0px;
  text-align:center;
}

div.customer div.orderList div.order div.buttonDelete img{
  width:17px;
  height:auto;
}

div.customer div div.orderList div.order div.orderListHeader div{
  background: transparent;
  color:var(--color-font-one);
  font-weight:bold;
  

}

div.customer div div.orderList div.order div.orderListHeader{
  padding: 5px;
}

div.customer div div.orderList div.order div.toolbar div{
  background: var(--color-main-button);
  display:inline-block;
  padding:10px;
  margin-top:10px;
  border-radius: 10px;
  margin-right:10px;
 
}

div.customer div div.orderList div.order div div.toolbar div a, div.customer div div.orderList div.order div div.toolbar div a:visited{
  color:var(--color-font-one-negative);
  text-decoration: none;

}

div.customer div div.orderList div.order div.toolbar div.deleteButton{
  background:var(--color-main-button);
}

div.customer div div.orderList div.order div.toolbar div.sentButton{
  width:calc(100% - 20px);
  text-align:center;
  background:var(--color-order-sent-bar);
  font-weight:bold;
}

div.customer div div.orderList div.order div.toolbar div.addProductsLabel{
  background:transparent;
}

div.customer div div.orderList div.order div.toolbar div.sendButton{
  background: var(--color-message-ok);
  float:right;
  margin-right:0px;
}

div.customer div div.orderList div.order div div.orderDetails input, 
div.customer div div.orderList div.order div div.orderDetails textarea{
  font-size: 16px;
  border:0px solid transparent;
  padding:10px;
  border-radius: 5px;
  margin-top:10px;
  margin-bottom:10px;
  width:400px;
}

div.customer div div.orderList div.order div div.orderDetails button.saveButton{
  font-size: 16px;
  border:0px solid transparent;
  padding:10px;
  border-radius: 10px;
  margin-top:10px;
  margin-bottom:10px;
  width:160px;
  color:var(--color-font-one-negative);
  background: var( --color-main-button-two);
}

div.customer div div.orderList div.order div div.orderDetails button.saveButton.edited{
  
  background: var( --color-main-button);
}

div.customer div div#userDetails.userDetails div.errorMessage.visible,
div.customer div div#userDetails.userDetails div.message.visible{
  height:40px;
  background:#c5050a;
  width:calc(400px + 10px);
  border-radius: 5px;
  margin-bottom:15px;
  margin-top:10px;
  transition: height 300ms linear 0s;
  overflow: hidden;
}

div.customer div div#userDetails.userDetails div.message.visible{
  background: green;
}

div.customer div div#userDetails.userDetails div.errorMessage.hidden,
div.customer div div#userDetails.userDetails div.message.hidden{
  height:0px;
  transition: height 300ms linear 0s;
}

div.customer div div#userDetails.userDetails div.errorMessage.visible div,
div.customer div div#userDetails.userDetails div.message.visible div{

  padding:10px;
}

div.customer div div.customerNews{
  background:var(--color-bg-main);
  padding:10px;
  margin-top:10px;
  border-radius: 10px;
}

div.customer div div.customerNews div.customerNewsHeader{
  background:transparent;
  display:flex;
  align-items: center;
  margin-bottom:10px;
}
div.customer div div.customerNews div.customerNewsHeader img{
  width:30px;
  height:30px;
  padding-right:10px;
}

div.customer div div.customerNews  div.news{
  background: var(--color-bg);
  margin-bottom:10px;
  padding:10px;
  border-radius: 10px;
}

div.customerNews div.news{
  /*background:grey;*/
}

div.customerNews div.news img.newsImage{
  width:100% !important;
  max-width: 50%;
  float:right;
  height:auto !important;
  border-radius: 10px;
}

div.customerNews div.news p{
  margin:0px;
}

div.news a{
  color:var(--color-font-one);
  text-decoration: none;
  font-weight: bold;
  font-size:20px;
}
@media (max-width: 768px) {
  
  div.customerNews div.news img.newsImage{
    height:auto !important;
    width:100% !important;
    max-width: 600px;
    float:none;

  }

}
