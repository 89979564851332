.productList{
    border:2px solid transparent;
    border-radius: 10px;
    position: relative;
    top:10px;
    margin-left:10px;
    width:calc(100% - 20px);
    padding-bottom:50px;
}

.productList .product{

    
    background: var(--color-bg-product-list);
    padding:0px;
    margin:0px;
    border-radius: 10px;
    border:0px solid white;
    margin-bottom:10px;
    box-shadow: 0px 2px 3px 0px rgba(7, 7, 6, 0.3);
}

.productList .product:hover,
.productGrid .product:hover{
	background:var(	--color-bg-hover);
}




.productList .product .productImage{
    width:200px;
    height:auto;
    float:left;
    margin-top:10px;
    margin-left:10px;
    margin-bottom:10px;
    border-radius: 10px;
    background:var(--color-black);
}

div.productList div div.productListSection,
div.productGrid div div.productGridSection{
    background:var(--color-bg-header);
    border-radius: 10px;
    padding:10px;
    margin-bottom:10px;
}
div.productGrid div.product div.productDetails div.productGridSection b.brand{
    font-size: calc(0.5vw + 12px);
    color:var(--color-font-one-negative);
}
div.productList div div.productListSection h3{
    color:var(--color-font-one-negative);
}

.productList .product .productDetails{
    background: transparent;
    float:right;
    width:calc(100% - 230px);
    margin-top:10px;
    margin-left:5px;
}


div.product div.productDetails b.brand{
    font-size: calc(0.5vw + 8px);
    color: var(--color-font-two);
}

div.product div.productDetails b.artnr{
    color: var(--color-font-two);
    font-size: calc(0.5vw + 10px);
}

div.product div.productDetails p.descShort{
    color: var(--color-font-two);
    margin:0px;
    font-size: calc(0.5vw + 10px);
    /*background:white;
    border-radius: 10px;*/
}

.product .productDetails h3.productName{
    font-size: calc(0.5vw + 10px);
}


div.productGrid div.product div.moreBox, div.productList div.product div.moreBox{
    background: var(--color-bg-main);
    width:calc(100% - 30px);
    margin-top:10px;
    padding:10px;
    margin-right:0px;
    border-radius: 5px;
    margin-bottom:10px;
   
}

div.productList div.product div.moreBox{
    margin-right:10px;
}

div.productList div.product h3{
    color:var(--color-font-one);
}

div.productGrid div.product div.more, 
div.productList div.product div.more {
    background: transparent;
    padding:0px;
    border-radius: 5px;
    /*width:120px; FP:2020-10 */
    font-size: calc(0.4vw + 10px);
    margin-top:0px;
    margin-bottom:0px;
    display: flex;
    flex-direction: row;
    align-items: center;


		
		

}


/*div.productList div.product div.more {
		background:green;
	margin-bottom:5px;
	padding:0px;

}*/

div.productList div.product div.productDetails div div.more{ /* FP: */
		/*padding-bottom:10px;*/
		padding:5px;
		display:inline-block;
  
		
			background: var(--color-font-two-negative);
	margin-bottom:5px;
	padding:5px;
	
	display: inline-flex;
	 align-items: center;
	 border-radius: 10px;

}

div.productList div.product div.productDetails div div.more:hover{
	background:var(--color-main-button-break);
}

div.productList div.product div.productDetails div div.more div.label{
	color:var(  --color-font-one-negative);
	
}

div.productList div.product div.productDetails div div div.productBrand{
	display: none;
}



div.productGrid div.product div.more img{
    width:18px;
    height:18px;
    float:left;
    padding-right:5px;
    display:block;
}

div.productList div.product div.more img{
	    width:18px;
    height:18px;
  
    padding-right:5px;
    display:inline-block;
}


 .product .productDetails .debug{
    background:#3d3d3e;
    padding:10px;
    margin:10px 10px 10px 0px;
    border-radius: 10px;
}

/**/


div.productList div.product div.productDetails div div.moreBox div.productOption{
    background: var(--color-bg);
    min-height:200px;
    margin-bottom: 10px;
    float:left;
    width:calc(100% - 20px);
    padding:10px;
    border-radius: 10px;
}

div.productList div.product div.productDetails div div.moreBox div.productOption table{
    width:100%;
}

div.productList div.product div.productDetails div div.moreBox div.productOption table tr.productOptionHeader td{
    background:var(--color-bg);
    font-weight: bold;

}

div.productList div.product div.productDetails div div.moreBox div.productOption table tr{
    background:var(--color-main-button-two);

}

div.productList div.product div.productDetails div div.moreBox div.productOption table tr td{
    background: var(--color-bg-main);
}

div.productList div.product div.productDetails div div.moreBox div.productOption table tr td{
    padding: 3px;
}

div.productList div.product div.productDetails div div.moreBox div.productOption table tr td.outlet{
    color:var(--color-font-outlet-negative);
}


div.productList div.product div.productDetails div div.productExtraInformation div.description,
div.productList div.product div.productDetails div div.productExtraInformation div.connectedProductList,
div.productList div.product div.productDetails div div.productExtraInformation div.mcList,
div.productList div.product div.productDetails div div.productExtraInformation div.categoryList,
div.productList div.product div.productDetails div div.productExtraInformation div.itemList{
    background:var(--color-bg);
    padding:10px;
    border-radius: 10px;
    margin-bottom:10px;
}

div.productList div.product div.productDetails div div.productExtraInformation div.connectedProduct,
div.productList div.product div.productDetails div div.productExtraInformation div.mc,
div.productList div.product div.productDetails div div.productExtraInformation div.category,
div.productList div.product div.productDetails div div.productExtraInformation div.item
{
    background: var(--color-main-button);
    padding:10px;
    margin-left:0px;
    margin-top:10px;
    border-radius: 10px;
    color:var(--color-font-one-negative);
    cursor: pointer;
}

div.productList div.product div.productDetails div div.productExtraInformation div.item a img{
    float:right;
    width:20px;
    height:20px;


}
div.productList div.product div.productDetails div div.productExtraInformation div.item a{
    color:var(--color-font-one-negative);
    text-decoration: none;
}


div.productList div.product div.productDetails div div.productExtraInformation div.connectedProduct:hover,
div.productList div.product div.productDetails div div.productExtraInformation div.mc:hover,
div.productList div.product div.productDetails div div.productExtraInformation div.category:hover,
div.productList div.product div.productDetails div div.productExtraInformation div.item:hover{
    background: var(--color-main-button-break);
}

div.productList div.product div.productDetails div div.moreBox div.productOption table tr.productOptionHeader td.productBuyButtonCell{
    width:80px;
}

div.productList div.product div.productDetails div div.moreBox div.productOption table tr.productOptionHeader td.productStockCell{
    width:80px;
}


div.productList div.product div.productDetails div div.moreBox div.imageListWrapper{
    background: var(--color-bg);
    width:100%;
    overflow-x: auto;
    overflow-y: hidden;
    margin-bottom:10px;
    border-radius: 10px;

}
div.productList div.product div.productDetails div div.moreBox  div.imageListWrapper div.imageList{
    padding:10px;
    background: transparent;
    display: flex;
    align-items: baseline;
    justify-content: start;
    height:150px;
    border-radius: 10px;
    margin-bottom:10px;
    margin-top:0px;
    /*width: calc(100vw - 80px);*/
    width:10px;
    
    
}
div.productList div.product div.productDetails div div.moreBox div.imageList div.imageItem img{
    
    background: transparent;
    max-height:150px;
    border-radius: 10px;
    margin-right: 10px;
    
    
}

.spinnerProductReadMore{
    position: absolute;
    background: none;

    padding-top:5px;
    margin-right:10px;
    right:0px;
    opacity: 0.5;
    z-index:10;
    
    
}

.spinnerProductReadMore img{
    background: none;
    width:40px;
}




div.productList div.product div.productDetails div div.more img.reload{
    padding-left:5px;
}

div.productList div.product div.productDetails div div.more img.reload.spinning{
        -webkit-animation:spin 1s linear infinite;
        -moz-animation:spin 1s linear infinite;
        animation:spin 1s linear infinite;   
}
    
@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }

div.productList div.product div.productDetails div div.moreBox div.reloadReadMore{
    background: var(--color-main-button);
    display:inline-block;
    padding:10px;
    margin-bottom:10px;
    border-radius: 10px;
}

div.productList div.product div.productDetails div div.moreBox div.reloadReadMore h4{
    color: var(--color-font-one-negative);
    font-size: calc(0.3vw + 10px);
    display: inline-block;
    margin:0px;

}

div.productList div.product div.productDetails div div.moreBox div.reloadReadMore.button img.reload{
    width:26px;
    height:26px;
    margin-left:10px;
}
div.productList div.product div.productDetails div div.moreBox div.reloadReadMore{
    display: inline-flex;
    align-items: center;
    

}

@media (max-width: 768px) {

    .productList{
        top:20px;
    }
    .productList .product .productImage{
        width:24%;
    }
    
    .productList .product .productDetails{
        width:calc(74% - 10px);
    }

    div.productGrid div.product div.more img, div.productList div.product div.more img{
        width:18px;
    }


div.productList div div.productListSection,
div.productGrid div div.productGridSection{
    border-radius: 10px;
    padding:5px;
    padding-left:10px;
    
}

    /*
    .productList{
        top:0px;
    } 

    .productList .product .productDetails{
        float:left;
        width:calc(100% - 10px);
        margin:10px;
    }

    .productList .product .productImage{
        width:calc(100% - 20px);
        margin:10px;
    }
*/
}

/* size up image
    width: 600px;
    height: auto;
    max-height: 230px;
    border-radius: 10px;
    display: none;
    position: relative;
    top: 0px;
    left: 0px;
    margin: 0 auto;
        margin-left: auto;
    display: block;
    float: left;
    margin-left: -50%;
}*/
