div.findResellerList{
  background: var(--color-bg-main);
}

div.findResellerList div.findResellerHeader h1{
    font-size: calc(1.5vw + 10px);
}

div.findResellerList div.findResellerHeader{
  background: var(--color-bg);
  padding:10px;
  display: flex;
  align-items: center;
  height:50px;
  position: sticky;
  top:60px;
  z-index: 8;
}

div.findResellerList div.findResellerHeader a{
  display: flex;
  align-items: center;

}

div.findResellerList div.findResellerHeader img{
  width:30px;
  height:30px;
  margin-right:10px;
}

div.findResellerList div.bliaf{
  background: var(--color-bg);
  cursor: pointer;
  padding:10px;


  
}

div.findResellerList div.bliaf a{
  display: flex;
  
  align-items: center;
  
  text-decoration: none;

}

div.findResellerList div.bliaf a h3{
  display: inline-block;
  color:var(--color-font-one);
  font-size: calc(0.6vw + 8px);
  font-weight: bold;
}

div.findResellerList div.bliaf a img{
  height:16px;
  width:16px;
  padding-left:5px;

}


div.findResellerList div.searchField{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width:calc(50% - 40px);
  background: var(--color-bg-main);
  padding:10px;
  margin:10px;
  margin-top:0px;
  border-radius: 10px;
  height:32px;
}

div.findResellerList div.searchField input{
  padding:5px;
  border:0px solid transparent;
  width:calc(100% - 50px);
  background: transparent;
  font-size:18px;
  color:var(--color-font-one);
}

div.findResellerList div.searchView{
  display: flex;
  width:100%;
  position: sticky;
  top:135px;
  z-index: 8;
  padding-top:10px;
  background: var(--color-bg);
}

div.findResellerList div.searchField img{
  width:30px;
  height:30px;
}


div.findResellerList div.button{
  display: inline-block;
  background: var(--color-main-button);
  border-radius: 10px;

  margin:10px;
  margin-top:0px;
  padding:10px;
  padding-right:20px;
  display: flex;
  justify-content: center;

  
}

div.findResellerList div.button h3{
  color:var(--color-font-one-negative);
}

div.findResellerList div.button img{
  margin-right:10px;
  width:30px;
  height:30px;
}

div.findResellerList div.findReseller{
  background: var(--color-bg);
  display: flex;
  padding:10px;
  margin:10px;
  border-radius: 10px;

}

div.findResellerList div.findReseller div.letter{
  background: var(--color-bg-reseller-letter);
  width:40px;
  min-width:40px;
  height:40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  height:40px;
  margin-right: 10px;
  opacity: 1;
 
  
}
div.findResellerList div.findReseller div.letter h1{
  color:var(--color-font-one-negative);
}
div.findResellerList div.findResellerResult div.findReseller div.letter.hidden{
  opacity: 0;
}

div.findResellerList div.findResellerResult{
  clear:both;
  max-width: calc(100% - 0px);
 

}

div.findResellerList div.findReseller div.reseller{
  border:1px solid transparent;
  width:100%;
}

div.findResellerList div.findReseller h3{
  font-size: calc(0.6vw + 8px);
}



div.findResellerList div.findReseller h1{
  font-size: calc(0.8vw + 10px);
}

div.findResellerList div.findResellerResult div.findReseller div.reseller div.information div.resellerHeader h1{
  margin-right:10px;
}

div.findResellerList div.findReseller div.information{
  border:1px solid transparent;
  width:calc(100%);
  float:left;
}

div.findResellerList div.findReseller div.details{
  float:none;
  border:0px solid black;
  width:calc(100% - 10px);
  float:left;
}
div.findResellerList div.findReseller div.type{
  border:0px solid transparent;
  width:170px;
  float:right;
  text-align: right;
}

div.findResellerList div.findResellerResult div.findReseller div.reseller div.information div.contactButton,
div.findResellerList div.findResellerResult div.findReseller div.reseller div.details div.contactButton{
  background: var(--color-main-button);
  display: flex;
  margin-bottom:10px;
  align-items: center;
  border-radius: 10px;

  float: left;
  margin-right:5px;
  margin-top:10px;
  padding-right:5px;
}

div.findResellerList div.findResellerResult div.findReseller div.reseller div.information div.contactButton img,
div.findResellerList div.findResellerResult div.findReseller div.reseller div.details div.contactButton img{
  width:20px;
  height:20px;
  margin:5px;
  

}


div.findResellerList div.findResellerResult div.findReseller div.reseller div.information a div.contactButton h3{
  padding-left:0px;
  color:var(--color-font-one-negative);
}

div.findResellerList div.findResellerResult div.findReseller div.reseller div.information a div.contactButton.www h3{
  padding-left:5px;
}

div.findResellerList div.findResellerResult div.findReseller div.reseller div.details div.contactButton{
  min-width:200px;
  float:none;
  width:66%;
}


div.findResellerList div.findResellerResult div.findReseller div.reseller div.information img{
  width:30px;
}



div.findResellerList div.findResellerResult div.findReseller div.reseller div.type{
  text-align: right;
}

div.findResellerList div.findResellerResult div.findReseller div.reseller div.type img{
  width:40px;
  height:40px;
  margin-left:5px;
}

div.findResellerList div.findResellerResult div.findReseller div.resellerHeader{
  display: flex;
  align-items: center;
}

div.findResellerList div.findResellerResult div.findReseller div.resellerHeader img.readMore{
  padding-left:10px;
  width:16px;
  height:16px;
  max-width: 16px;
}



div.findResellerList div.searchView div.button.off{
  background: var(--color-main-button);
}



div.findResellerList div.searchView div.button.off h3{
  color:var(--color-font-two-negative);
}

div.findResellerList div.findResellerResult div.findReseller div.reseller div.information h3.address{
  color:var(--color-font-two);
}

div.findResellerList div.findResellerResult div.findReseller div.reseller div.details{
  margin-top:0px;

}

div.findResellerList div.findResellerResult div.findReseller div.reseller a div.contactButton h3,
div.findResellerList div.findResellerResult div.findReseller div.reseller a{
  color:var(--color-font-one-negative);
  text-decoration: none;
}


@media (max-width: 768px) {
  div.findResellerList div.searchView{
    display: block;
    padding-bottom:10px;
    padding-top:30px;
   /* top:130px;*/
  }

  div.findResellerList div.searchField{
    width:calc(100% - 30px);
    margin-top:0px;
    margin-left:10px;
    margin-right:10px;

    padding:3px;
  }

  div.findResellerList div.button{
    float:left;
    margin:0px;
    margin-left:10px;
    margin-right:10px;
    padding:0px;
    padding-left:10px;
    padding-right:10px;
    padding-top:5px;
    padding-bottom:5px;
    
  }



  div.findResellerList div.findResellerResult div.findReseller div.reseller div.details div.contactButton{
    min-width:200px;
    float:none;
    width:100%;
   
  }


div.findResellerList div.findResellerResult div.findReseller div.reseller div.type img{
  width:25px;
  height:25px;
  margin-left:2px;
}

div.findResellerList div.findReseller div.type{
  
  width:85px;
 
}

div.findResellerList div.findReseller div.letter{
  display: none;
}

div.findResellerList div.findResellerResult div.findReseller div.reseller div.information div.resellerHeader h1{
  margin-right:0px;
}


div.findResellerList div.findResellerHeader{
  padding:0px;
  padding-left:10px;
  height:40px;
}

div.findResellerList div.searchView{
  top:110px;
  padding-top: 10px;

}

div.findResellerList div.bliaf{
  padding-top:0px;
  
}

div.findResellerList div.bliaf a{
  padding:0px;
  padding-left:5px;
  padding-right:5px;
  padding-bottom:5px;
}

div.findResellerList div.bliaf a img{
  height:12px;
  width:12px;
  padding-left:5px;

}

  
}