div.motulSearch{

	padding:10px;

}


div.motulSearch div.search{
	display: flex;
}

div.motulSearch  img.toggle{
	width:25px;
	height:25px;
	margin-right:5px;
}

div.motulSearch  img.reset{
	width:20px;
	height:20px;
	margin-right:5px;
}


div.motulSearch div.section div.search div.searchBoxWrapper{
	
	display: flex;
	align-items:center;
	background:var(--color-bg);
	border-radius:10px;
	margin-right:5px;
}

div.motulSearch div.search input.searchBox{
background:transparent;
border:0px solid transparent;
border-radius:10px;
padding-left:5px;

outline: none;


}

div.motulSearch div.button{
  display: inline-block;
  background: var(--color-font-two-negative);
  border-radius: 10px;
	color:var(--color-font-one-negative);
  margin:0px;
  margin-top:0px;
  padding:5px;
	padding-right:10px;
	padding-left:10px;
  

  
}



div.motulSearch div div div div.product{
	background:rgb(214, 213, 213);
	border-radius: 5px;
	margin-bottom:5px;
	padding:5px;
	display:flex;
	align-items:center;
}

div.motulSearch div.section{

padding:10px;
background:var(--color-bg-header);
border-radius:10px;
margin-bottom:10px;
color:white;
}

div.motulSearch div.section h3{
margin:0px;
padding:0px;
margin-bottom:5px;
display:inline-block;
}

div.motulSearch div.modelSectionHeader.result div div.recommendation div div.placement div.intervalList div.interval div.recommendedProduct div.motulProduct div.motulProductText div.badgeList div{
	background:var(--color-message);
	display: inline-block;
	padding:3px;margin-right:5px;
	border-radius: 5px;
	margin-top:3px;

	font-size: clamp(10px, calc(0.5vw + 8px), 15px);
}

div.motulSearch div.modelSectionHeader.result div div.recommendation div div.placement div.intervalList div.interval div.recommendedProduct div.motulProduct div.motulProductText div.badgeList div.badgeIntervalInitial{
background:var(--color-message-warning);
}

div.motulSearch div.modelSectionHeader.result div div.recommendation div div.placement div.placementHeader b.spacer{
	flex-grow:1;
}

div.motulSearch div.modelSectionHeader.result div div.recommendation div div.placement div.placementHeader div.productCount{
	background:var(--color-bg);
	padding-left:5px;
	padding-right:5px;
	margin-right:5px;
	border-radius:5px;
}

div.motulSearch div.section img.spinner{
	width:20px;
	height:20px;
	margin-top:3px;

	
  -webkit-animation: spin 1s linear infinite;
  -moz-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;

}

div.motulSearch div.section div.sectionHeader{
	display:flex;
	gap:5px;
	align-items:start;

}
div.motulSearch div.section div.sectionHeader img,
div.motulSearch div.section div.sectionHeader h3{
		cursor: pointer;
}

div.motulSearch div.section select.recommendationList{
	margin-top:10px;
}

div.motulSearch div.result{
	background: var(--color-bg-header-topmenu);
	padding:10px;
	margin-bottom:10px;
	border-radius:10px;
}

div.motulSearch div.result div.model{
	display:flex;
}

div.motulSearch div.recommendation div div.placement div.placementHeader{
	display:flex;
	align-items:center;
}

div.motulSearch div.result  div.recommendation div div.placement{
	background:white;
	margin-bottom:10px;
	padding:5px;
	border-radius: 10px;
}



div.motulSearch div.result div.recommendation div div div.interval div.recommendedProduct{
	/*background: var(--color-bg-header);*/
	margin-bottom:10px;
	padding:10px;
}

div.motulSearch div.result div.recommendation div div div.interval div.recommendedProduct div.motulProduct{
	background:black;
	color:white;
	margin-bottom:10px;
	padding:10px;
	display:flex;
	border-radius: 10px;
	
}

div.motulSearch div.result div.recommendation div div div.interval div.recommendedProduct div.motulProduct div.motulProductImage img{
	width:100px;
	height:auto;
}

div.motulSearch div.result div.recommendation div div div.interval div.recommendedProduct table tr.productRow div.productBuyButton{
	display:inline-block;
	padding:0px;
	margin-right:0px;
	border-radius: 10px;
	padding:5px;
}

div.motulSearch div.result div.recommendation div div div.interval div.recommendedProduct table{
width:100%;
}


div.motulSearch div.result div.recommendation div div div.interval div.recommendedProduct table tbody tr.productRow.odd{
	background:var(--color-bg);
}

div.motulSearch div.result div.recommendation div div div.interval div.recommendedProduct table tbody tr.productRow.even{
	background:white;
}

div.motulSearch div.result div.recommendation div div div.interval div.recommendedProduct table thead tr th{
	background:black;
	padding:5px;
	color:white;
	text-align:left;
}

div.motulSearch div.result div.recommendation div div div.interval div.recommendedProduct table thead tr th.price{
	width:160px;
}

div.motulSearch div.result div.recommendation div div div.interval div.recommendedProduct table thead tr th.buy{
	width:120px;
}

div.motulSearch div.result div.recommendation div div div.interval div.recommendedProduct table thead tr th.artnr{
	width:160px;
}

div.motulSearch div.result div.recommendation div div div.interval div.recommendedProduct table thead tr th.stock{
	width:100px;
}

div.motulSearch div.result div.recommendation div div div.interval div.recommendedProduct table tbody tr.productRow td.stock div div.productStock{
	width:75px;
}


div.motulSearch div.result div.recommendation div div div.interval div.recommendedProduct table thead tr th.price, 
div.motulSearch div.result div.recommendation div div div.interval div.recommendedProduct table thead tr th.stock, 
div.motulSearch div.result div.recommendation div div div.interval div.recommendedProduct table thead tr th.buy  {
	text-align: right;
}

div.motulSearch div.result div.recommendation div div div.interval div.recommendedProduct table tbody tr.productRow td.stock,
div.motulSearch div.result div.recommendation div div div.interval div.recommendedProduct table tbody tr.productRow td.price{
	text-align:right;
	padding-right:5px;
}

div.motulSearch div.result div.recommendation div div div.interval div.recommendedProduct table tbody tr.productRow td.buy{
	text-align:right;
	padding-right:5px;
}

div.motulSearch div.result div.recommendation div div div.interval div.recommendedProduct table tr.productRow td{
padding:5px;
}

div.motulSearch div.result div.recommendation div div div.interval div.recommendedProduct table tr.productRow div.productPrice{
margin-right:5px;
}

div.motulSearch div.result div.recommendation div div div.interval div.recommendedProduct table tr.productRow div div.productStock.stockNONE{
	display:none;
}

div.motulSearch div.result div.recommendation div div div.interval div.recommendedProduct table tr.productRow div div.productStock{
	margin-right:5px;

}

div.motulSearch div.modelSectionHeader.result div div.recommendation div div.placement div.intervalList div.interval div.recommendedProduct{
	background:var(--color-bg-header-topmenu);
	margin-top:5px;
	border-radius: 10px;
}
