html body div#root div.App div.layout div.page{
  height: 100%;
  background: var(--color-black);
}

div.startPage div.startContent{
  background:var(--color-bg);
  padding:10px;
}

div.startPage div.startContent div.startContentHeader{
  background: var(--color-bg-main);
  margin-bottom:10px;
  padding:10px;
  border-radius: 10px;
}

div.startPage div.startContent div div.startContentHeader a{
  color:var(--color-font-one);
  text-decoration:none;
}

div.navigation div.navigationList{
  background: transparent;
  padding:0px;
  margin-bottom:10px;
  border-radius: 10px;
  display: flex;
  justify-content: flex-start;
  max-width: 1200px;
  cursor: pointer;


}

div.startPage div.navigation,
div.findBrandList div.navigation,
div.findResellerList div.navigation,
div.webPage div.navigation{
  background: var(--color-bg);
  width:calc(100% - 20px);
  padding:10px;
  position: sticky;
  top:60px;
  z-index: 9;

}

div.navigation div.navigationList div.navigationItem{
  background: var(--color-bg-header-topmenu-button);
  display: inline-block;
  padding:10px;
  border-radius: 10px;
  color:var(--color-font-one);
  flex-grow: 1;
  /*max-width: 125px;*/
  min-width: 100px;
  text-align: center;

}

div.navigation div.navigationList div.navigationItem.loading,
div.navigation div.navigationList div.navigationItem.loading a,
div.navigation div.navigationList div.navigationItem.loading a:visited{
  background: var(--color-bg-header-topmenu-button);
  color:var(--color-font-one-negative);
}

div.navigation div.navigationList div.navigationItem:hover,
div.navigation div.navigationList div.navigationItem a:hover{
  background:var(--color-main-navigation-button-hover);
  /*color:black;*/
}

div.navigation div.navigationList div.navigationItem, 
div.navigation div.navigationList div.navigationItem,
div.navigation div.navigationList div.navigationItem a{
  color:var(--color-font-header-button-off);
  text-decoration: none;
  
}

div.navigation div.navigationList div.space{
  max-width:10px;
}

div.startPage div.startContent div.startBox{
  background:var(--color-unknown);
  border-radius: 10px;
  padding: 10px;
  margin-bottom:10px;
  
  background: url("../gfx/bg-bike-blur.png") center center fixed;
  background-size: cover;
  
}

div.startPage div.startContent div.startBox img.startImage{
  width:100%;
  border-radius: 10px;
  margin-top:20px;
}

div.startPage div.startContent div.startBox div.startHtml{
  color:var(--color-font-one);
  margin-top:10px;

}

div.startPage div.startContent div.startContentHeader img,
div.startPage div.startContent div.startBox div.startHtml img{
  width:100% !important;
  height:auto !important;
  border-radius: 10px;
  max-width: 100%;
}

div.startPage div.startContent div.startBox div.startHtml a{
  color:var(--color-font-one);
  text-decoration: none;
  font-weight: bold;
}

div.startPage div.startContent div.startBox.closed.pt_mc{
  background: url("../gfx/start-header-mc.jpg") center center  #000000;
  background-size: auto;
  /*background: black;*/
}

div.startPage div.startContent div.startBox.closed.pt_cykel{
  background: url("../gfx/start-header-bike.jpg") center center   #000000;
  background-size: auto;
  
}

div.startPage div.startContent div.startBox.closed.pt_motul{
  background: url("../gfx/start-header-motul.jpg") bottom center   #000000;
  background-size: auto;
}
div.startPage div.startContent div.startBox div.startHeader{
  display: flex;
  align-items: center;
  color:var(--color-font-one-negative)
}

div.startPage div.startContent div div.productTypeList div div.startProductType.startBox div.startHeader h1{
  color:var(--color-font-one-negative);
}

div.startPage div.startContent div div.productTypeList div div.startProductType.startBox div div.startHtml,
div.startPage div.startContent div div.productTypeList div div.startProductType.startBox div div.startHtml a,
div.startPage div.startContent div div.productTypeList div div.startProductType.startBox div div.startHtml a:hover{
  color:var(--color-font-one-negative);
}

div.startPage div.startContent div.startBox div.startHeader img.icon{
  width: 50px;
  height:50px;
  padding-right:10px;
}

div.startPage div.startContent div.startBox .newsLabel,
div.startPage div.startContent div.startBox .productListLabel,
div.startPage div.startContent div.startBox .brandLabel
{
  color:var(--color-font-one-negative);
  margin-bottom: 10px;
  margin-top:10px;
}

div.startPage div.startContent div.startBox div.brandListWrapper{
  overflow-x: scroll;
  overflow-y: hidden;

  width:100%;
  height: 170px;
  margin-top:20px;
  border-radius: 10px;
  margin-bottom:20px;
}

div.startPage div.startContent div.startBox div.brandList{
  width:10px; /* FP: något hack för att få till så bredden skalas ned */ 
  white-space: nowrap;
  height: 120px;
  
}


div.startPage div.startContent div.startBox div.brandList div.startBrand{
  
  display:inline-block;
  padding:10px;
  margin-right:10px;
  border-radius: 5px;
  width:100px;
  height:100px;
  background: white;

  
}



div.startPage div.startContent div.startBox div.newsList div.startNews{
  background:var(--color-bg);
  padding:10px;
  margin-bottom:10px;
  border-radius: 10px;
}



div.startPage div.startContent div.startBox div.startFooter{
  background: var(--color-bg);
  padding:10px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
}

div.startPage div.startContent div.startBox div.startFooter img{
  width:26px;
  height:26px;
  margin-left:10px;
}

div.startPage div.startContent div.startBox a, 
div.startPage div.startContent div.startBox a div.startFooter h3,
div.startPage div.startContent div.startBox a:visited,
div.startPage div.startContent div.startBox a:visited div.startFooter h3

{
  color:var(--color-font-one);
  text-decoration: none;
  
}

div.startPage div.startContent div.startBox div.categoryList{
  background: transparent;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width:100%;
  flex-flow: row wrap;
  flex-wrap: wrap;
  align-content: flex-end;
  margin-top:10px;

}

div.startPage div.startContent div.startBox div.categoryList div.startCategory{
  background: var(--color-bg);
  display: inline-block;
  padding:10px;
  min-width:150px;
  flex: 1 0 26%;
  margin-right:10px;
  margin-bottom:10px;
  border-radius: 10px;
  min-height: 200px;
  text-align:center;
}

div.startPage div.startContent div.startBox div.categoryList div.startCategory img{
  width:100%;
  border-radius: 10px;
  
}


div.startPage div.startContent div.retailView{
  background: var(--color-bg-main);
  padding:10px;
  border-radius: 10px;
}

div.startPage div.startContent div.retailView h1 a{
  color:var(--color-font-one);
  text-decoration:none;
}

div.startPage div.startContent div.retailView div.retailList div.retail{
  display: inline-block;
  background: var(--color-black);
  padding:10px;
  border-radius: 10px;
  min-width:100px;
  text-align: center;
  
  
}

div.startPage div.startContent div div.retailView div.retailList div.retail a h3,
div.startPage div.startContent div div.retailView div.retailList div.retail a{
  color:var(--color-font-one);
  text-decoration: none;

}

div.startPage div.startContent div.retailView div.retailList div.space{
  background: transparent;
  width:10px;
}

div.aboutView{
  background: var(--color-bg-start-footer);
  margin-top:0px;
  border-radius: 0px;
  padding:20px;
  min-height: 400px;

}

div.startPage div.startContent  div.productTypeList div div.startProductType.startBox div div.productGrid{
  background: transparent;
  display: flex;
  flex-wrap: nowrap;
  overflow: scroll;
  width: calc(100% - 0px);
  max-width: calc(100vw - 40px);
  padding-bottom: 20px;
  margin-left:0px;
 

}

div.startPage div.startContent  div.productTypeList div div.startProductType.startBox div div.productGrid div.product{
  min-width: 180px;
  margin:0px;
  margin-right:10px;
  padding:10px;
}


/* nyhetsformatering */ 

div.startPage div.startContent div div.productTypeList div div.startProductType.startBox div div.newsList div.startNews div.open p.html img.newsImage{
  float:left;
  width:100% !important;
  height:auto !important;
  max-width:30%;
  border-radius: 10px;
  height:auto;
  margin-right:10px;



}

div.startPage div.startContent div div.productTypeList div div.startProductType.startBox div div.newsList div.startNews p{
  margin:0px;
  padding:0px;
}

div.startPage div.startContent div div.productTypeList div div.startProductType.startBox div div.newsList div.startNews a,
div.startPage div.startContent div div.productTypeList div div.startProductType.startBox div div.newsList div.startNews a.visited{
color:var(--color-font-one);
text-decoration: none;
font-weight: bold;
}

div.startPage div.startContent div div.productTypeList div div.startProductType.startBox div div.newsList div.startNews div.closed img{
  width:30px;
  height:30px;
  padding-top:2px;
  margin-right:10px;
}

div.startPage div.startContent div div.productTypeList div div.startProductType.startBox div div.newsList div.startNews div.closed{
  display: flex;
}

div.startPage div.startContent div div.productTypeList div div.startProductType.startBox div div.newsList div.startNews div.closed h4,
div.startPage div.startContent div div.productTypeList div div.startProductType.startBox div div.newsList div.startNews div.closed h2{
  margin:0px;
  padding:0px;
}

div.startPage div.startContent div div.productTypeList div div.startProductType.startBox div div.newsList div.startNews div.closed h3.date{
  color:var(--color-font-one);
}

div.startPage div.startContent div div.productTypeList div div.startProductType.startBox div div.newsList div.startNews div.open img.button{
  clear:both;
  width:30px ;
  height:30px;
  margin-top:10px;
}

div.startPage div.startContent div div.productTypeList div div.startProductType.startBox div div.newsList div.startNews div.closed img.button{
  width:30px;
  height:30px;
}


/* */
div.startPage div.aboutView div.footer{
  text-align: center;
  background: transparent;
  font-weight: bold;
  color:var(--color-font-two);
}

div.startPage div.aboutView div.about.desktop table tr td{
  vertical-align: top;
  width:50%;
}

div.startPage div.aboutView div.about.desktop table{
  width:100%!important;
  border:0px solid transparent !important;
}

div.startPage div.aboutView h1{
  /*background:var(--color-bg-main);*/
  background: transparent;
  padding:5px;
  border-radius:10px;
  margin-bottom:20px;
  padding-left:0px;
  color:var(--color-font-one-negative);
}

html body div#root div.App div.layout div.page div.startPage div.aboutView div.about{
  color:var(--color-font-one-negative);
}

/* layouta produktlistan */
div.startPage div.startContent div div.productTypeList div div.startProductType.startBox div div.productGrid div.product{
  min-width: 300px;
}

html body div#root div.App div.layout div.page div.startPage div.aboutView button{
  background: var(--color-bg-header-topmenu-button);
  border:0px solid transparent;
  margin-right:10px;
  padding:10px;
  border-radius: 10px;
  color:var(--color-font-one-negative);
  font-weight: bold;
}

@media (max-width: 768px) {
  
  div.startPage div.startContent div.retailView div.retailList, 
  div.navigation div.navigation{

    justify-content: space-between;

  }

  div.startPage div.startContent div.retailView div.retailList div.retail{
    flex-grow: 1;
    min-width:50px;
  }

  div.startPage div.startContent div.retailView div.retailList div.retail h3,
  div.navigation div.navigationItem h3{
    color:var(--color-font-one);
    font-size: calc(1.0vw + 10px);
  }

  div.startPage div.startContent div.retailView div.retailList div.retail,
  div.navigation div.navigationItem{
    padding:0px;
  }

  div.navigation div.navigationList div.navigationItem{
    padding:0px;
    min-width: auto;
  } 
  div.navigation div.navigationList div.navigationItem h3,
  div.startPage div.startContent div div.retailView div.retailList div.retail h3
  {

    font-size: calc(0.5vw + 10px);
    padding-top:2px;
    color:var(--color-font-one-negative);
    padding-top:10px;
    padding-bottom:10px;
    
  }


  div.startPage div.startContent div div.productTypeList div div.startBox div.startHeader h1,
  div.startPage div.startContent div div.retailView h1,
  div.startPage div.aboutView h1{
    font-size:calc(2.0vw + 16px);
    color:var(--color-font-one-negative);
    
    }
    
    div.startPage div.startContent div.startBox div.startHeader img.icon{
      width:32px;
      height:32px;
      padding-right:10px;
    }


  div.navigation div.navigationList div.navigationItem.loading,
  div.navigation div.navigationList div.navigationItem.loading a,
  div.navigation div.navigationList div.navigationItem.loading a:visited{
    background: transparent;
    color:var(--color-bg-header-topmenu-button);
  }

}



@media (max-width: 508px) {

  div.startPage div.startContent div div.productTypeList div div.startProductType.startBox div div.newsList div.startNews p.html img.newsImage{
    width:100% !important;
    height:auto !important;
    border-radius: 10px;
    height:auto;
    margin-right:0px;
    float: none;
    max-width: 100% !important;
    
  }
  div.navigation div.navigationList div.navigationItem{
    background: transparent;
    padding-top:0px;
    padding-bottom:0px;
  }

  div.navigation div.navigationList div.navigationItem h3{
    color:var(--color-main-navigation-508);
    /*font-size: 10px;*/
    
  }
    
  div.navigation div.navigationList div.navigationItem h3.loading{
    display: none;
  }

  div.navigation div.navigationList div.navigationItem:hover{
    background: var(--color-main-navigation-bg-button-hover-508);
    
  }

}
