div.dropMenuMC div.mcTree, div.dropMenuMC div.mcList{ 
    background: var(--color-header-topmenu);
   width: 50%;
   float:left;
   overflow: scroll;

height: calc(100% - 80px);
}

div.dropMenuMC div.mcBrandGrid{
    background: transparent;
    width: 50%;
    float:right;
    position:sticky;
    top:70px;
}

div.dropMenuMC div.mcBrandGrid img{
    max-width: 250px;
    width:100%;
    
}




div.dropMenuMC div.mcBrandGrid table{
    border-spacing: 10px;
}

div.dropMenuMC div.mcBrandGrid table tr td{
    padding: 10px;
    background: var(--color-main-button);
    border-radius: 10px;
}

div.dropMenuMC div.mcBrandGrid div.brand{

   float:left;
   background: var(--color-main-button);
   border-radius: 10px;
   padding:10px;
   margin:0px;
   margin-left:5px;
   margin-bottom:10px;

}


div.yearDivStyleBlock{
   border: '1px solid grey';
   display:'block';
   clear:'both';
   overflow: 'hidden';

}

div.yearDivStyleNone{
   display: none;
}

div.mcListYear{
   font-size:0.8em;
   font-weight:bold;
   color: var(--color-font-one-negative);
   float:left;
   padding: 3px;
   margin: 5px;
   margin-left:0px;
   background: var(--color-main-button);
   display:inline-block;
   border-radius: 5px
}


div.mcList div.listModell div.yearDivStyleBlock div.mcListYear{ /* FP: När man söker */
    background: var(--color-main-button-two);
}

div.mcTree div.mcTreeBrand{
   background: var(--color-main-button);
   margin:5px;
   margin-left:10px;
   margin-bottom:10px;
   padding:5px;
   border-radius: 10px;

}

div.mcTree, 
div.mcList{
    color:var(--color-font-one-negative);
}

div.mcTree div.mcTreeBrand h3{
   margin:5px;
   
}

div.mcTree div.mcTreeBrand div.mcTreeModellList{
   background:transparent;
   margin:0px;
}



div.mcTree div.mcTreeBrand div.mcTreeModellList div.mcTreeModell{
   background:var(--color-bg-dropmenu-tree);
   margin:0px;
   margin-bottom:5px;
   padding:5px;
   border-radius: 10px;
  
}

div.mcList div.listModell{
   background:var(--color-bg-dropmenu-list);
   margin:10px;
   padding:5px;
   border-radius:10px;

   

}


div.mcList div.listModell img.showYearButton,
div.mcTree div.mcTreeModell img.showYearButton{
    width:20px;
    float:left;
    margin-right:5px;
}

