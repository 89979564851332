div.searchMenu{
    background:var(--color-bg-header-topmenu);
    
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 160px;
    /*top: 210px;*/
    /*display:block;*/
    z-index: 4;
    height: 50px;
    box-shadow: 0px 5px 5px 0px rgba(7, 6, 6, 0.3);

    
}

div.searchMenu div.searchField{
		/* FP: 20240701 background: var(--color-bg-header-topmenu-button); */
		background: var(--color-bg);
    float:left;
    margin:0px;
    margin-left:10px;
    border-radius: 10px;
		width: calc(100% - 150px);
		display:flex;
}

div.searchMenu div.searchField.wide{
		width: calc(100% - 20px);
}

div.searchMenu div.searchField div.searchFieldLabel{
	background:var(--color-bg-header-topmenu-button);
	border-radius:10px 0px 0px 10px;
	padding-left:10px;
	padding-right:10px;
	display:flex;
	align-items: center;	

}

div.searchMenu div.searchField div.searchFieldLabel h4{
	margin:0px;
	color:white;

	

}

div.searchMenu div.buttonHistory{
    background: var(--color-bg-header-topmenu-button);
    float:right;
    display: flex;
    align-items: center;
    height:40px;
    max-width:100px;
    min-width: 50px;
    padding-left:10px;
    margin-right:10px;
    border-radius: 10px;
}

div.searchMenu div.buttonHistory img{
    width:24px;
    min-width:24px;
    height:24px;
    
}

div.searchMenu div.buttonHistory h1{
    font-size: calc(0.3vw + 12px);
    width:100%;
    background: transparent;
    text-align: center;
    margin:5px;
    color:var(--color-font-one-negative);
    margin-right:10px;
}

div.searchMenu input{
   
    font-size: 18px;
    margin:5px;
    padding:5px;
		/*color:var(--color-font-one-negative);*/
		color:var(--color-font-one);
    background: transparent;
    border:0px;
    width: calc(100% - 60px);

}


div.searchMenu div.searchField img{
		width:30px;
		height:30px;
    right:10px;
    top:6px;
    margin-left:10px;
    position:relative;

   
}
div.searchMenu div.searchField input{
	outline: none;
}

@media (max-width: 768px) {
    div.searchMenu{
        top: 60px;
    } 

    div.searchMenu div.searchField{
        margin:5px;
        /*width: calc(100% - 10px);*/
        width: calc(100% - 85px);
    }

    div.searchMenu div.searchField input{
   
        font-size: calc(0.7vw + 12px);
        background:transparent;
        width: calc(100% - 45px);
        padding:2px;
		}
		
    div.searchMenu div.searchField h4{
        font-size: calc(0.6vw + 10px);
    }

    div.searchMenu div.searchField img{
				width:18px;
				height:18px;
        right:5px;
        background: transparent;
    }


    div.searchMenu div.buttonHistory{
        height:32px;
        margin-top:5px;
    }

    div.searchMenu div.buttonHistory img{
        width:18px;
        height:18px;
        padding:0px;
    }
    
    

}










