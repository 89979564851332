div.productStock{
    display: inline-block;
    padding:5px;
    padding-left:10px;
    padding-right:10px;
    margin-top:5px;
    margin-bottom:5px;
    border-radius: 10px;
    color:var(--color-font-one-negative);
    font-size: calc(0.2vw + 12px);
}

div.productStock.stockOK{
    background:var(--color-stock-ok);
    color: var(--color-font-stock);
}

div.productStock.stockLOW{
    background:var(--color-stock-low);
    color: var(--color-font-stock);
}

div.productStock.stockNONE{
    background:var(--color-stock-order);
    color: var(--color-font-stock);
}

div.productStock img{
    width:12px;
    height:12px;
}

/* case med annan formattering */ 
div.moreBox div.productOption table tbody tr.productOptionRow td.productStockCell div div.productStock,
div.productDetails div.productOption table tbody tr.productOptionRow td.productStockCell div div.productStock{
    padding:0px;
    margin:0px;
    padding-left:10px;
    padding-right:10px;
}

