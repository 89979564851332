div.dropMenuManufactor, div.manufactorList{ 
    background: var(--color-header-topmenu);
    width: 100%;
 }
 

 div.dropMenuManufactor  div.manufactor{
 
    float:left;
    background:var(--color-unknown);
    border-radius: 10px;
    padding:10px;
    margin:10px;
 }
 
 
div.dropMenuManufactor div.dropMenuContent div.manufactorList div.manufactorListNode{
   background:var(--color-main-button);
   margin-bottom:10px;
   margin-right:10px;
   padding:10px;
   border-radius: 10px;
   float:left;
   width:250px;
   text-align: center;
   font-size:calc(1.0vw + 10px);
   cursor: pointer;
}
 


div.dropMenuManufactor div.dropMenuContent div.manufactorList div div.manufactorListNode.hasProduct{
    /*background: var(--color-main-button);*/
    color:var(--color-font-one-negative);
}

div.dropMenuManufactor div.dropMenuContent div.manufactorList div div.manufactorListNode.noProduct{
    /*background: var(--color-main-button);*/
    color:var(--color-font-two-negative);
}

div.dropMenuManufactor div.dropMenuContent div.manufactorList div.manufactorListNode:hover{
    background:var(--color-main-button-break);
 }
