div.productBuyButton{
    background: var(--color-main-button-buy);
    border-radius: 10px;
    width: 100%;
    max-width:100px;
    
}

div.productGrid div.product div.productDetails div.productPriceBuy{
    position: absolute;
    bottom:5px;
    width:calc(100% - 20px);
}


div.productBuyButton div.buyButtonHeader{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    text-align: center;
}

div.productBuyButton div.buyButtonHeader h4{
    color:var(--color-font-one-negative);
    margin:0px;
    padding:0px;
    padding-left:0px;
    display: block;
    width:100%;
}

div.productBuyButton div.buyButtonHeader img{
    width: 20px;
    padding-right: 5px;
}

div.productBuyButton div.packageSizes div.packageSize{
    
    display: block;
    background:var(--color-main-button-two);
    padding:2px;
    padding-left:5px;
    padding-right:5px;
    margin:5px;
    border-radius: 5px;
    text-align: center;
    color:var(--color-font-one-negative);
    
}

div.productBuyButton div.packageSizes{
    
    padding-bottom:1px;
    background: transparent;

}

div.productBuyButton div.packageSizes input.quantity{
    background: white;
    color:black;
    width:30%;
    
    font-size: calc(0.3vw + 12px);
    border:0px solid transparent;
    border-radius: 5px;
    margin-right:2px;
    text-align: right;
    padding-right:5px;
    max-height: 20px;
    margin-left:5px;
}

div.productBuyButton div.packageSizes h4.quantityButton{
    background: transparent;
    color:var(--color-font-one-negative );
    border:0px solid transparent;
    border-radius: 5px;
    max-height: 20px;
    font-size: calc(0.5vw + 12px);
    font-weight:none;
    margin:0px;
    padding:0px;
    display: inline-block;
    padding-left: 5px;
    
}


div.productOption table tbody tr.productOptionRow td.productBuyButtonCell div.productBuyButton div.packageSizes input.quantity{
    background: white;
    max-width:20px;
}

div.header div.product div.productOption table tr.productOptionRow td.productBuyButtonCell
{
    width: 70px;
}

div.productPriceBuy{
    display: inline-block;
    border:1px solid transparent;
    width:calc(100% - 10px);
    /*max-width: 300px;*/
}

div.productPriceBuy div.productOptionPrice{
    display: inline-block;

    margin-bottom: 5px;
    margin-right:0px;
    text-align:center;
    width:100%;
    background: var(--color-main-button);
    border-radius: 10px;
    padding:5px;
    min-width: 100px;
}

div.productPriceBuy div.productOptionPrice.outlet{

    background: var(--color-main-button-outlet);

}


div.productPriceBuy div.productBuyButtonWrapper{
    background: transparent;
    padding:0px;
    border-radius: 10px;
    margin-left:5px;
    float:right;
    
}

div.productPriceBuy div.productOnePrice{
    display: inline-block;

    border-radius: 10px;
   
    text-align: center;
    padding-top:5px;
    padding-bottom:5px;
    margin-right:0px;
    margin-bottom:10px;
    background: var(--color-main-button);
    width:100%;
    min-width: 100px;
}

div.productPriceBuy div.productOnePrice.outlet{
    background: var(--color-main-button-outlet);
}

div.productPriceBuy div.productOptionPrice div.productOptionCountLabel{
    color:var(--color-font-one-negative);
    font-size: calc( 0.3vw + 8px);
}

div.productPriceBuy div.productOptionPrice h4.priceLabel,
div.productPriceBuy div.productOnePrice h4.priceLabel{
    color:var(--color-font-one);
    margin:0px;
    padding-left:0px;
    padding-right:0px;
    width:calc(100% - 10px);
}

div.productPriceBuy div.productOptionPrice h4.priceLabel{
    width:100%;
}

div.productPriceBuy div.productOnePrice h4.priceLabel{
    padding:0px;
}
div.productPriceBuy div.productBuyButtonWrapper div.productBuyButton{
    padding-top:5px;
    padding-bottom:5px;
    min-width: 60px;
    margin:0px;
    background: var(--color-main-button-buy);
}

div.productPriceBuy div.productBuyButtonWrapper div.productBuyButton div.packageSize{
    background: var(--color-main-button);
    color:var(--color-font-one-negative);
}

div.productPriceBuy div.productBuyButtonWrapper div.productBuyButton img{
    width:20px;
}

div.productPriceBuy div.productOptionPrice h4.priceLabel,
div.productPriceBuy div.productOnePrice h4.priceLabel,
div.productPriceBuy div.productBuyButtonWrapper div.productBuyButton div.buyButtonHeader h4{
    color: var(--color-font-one-negative);
    font-size: calc(0.5vw + 12px);
    display: inline;
}

div.productPriceBuy div.productOptionPrice h4.priceLabel.outlet,
div.productPriceBuy div.productOnePrice h4.priceLabel.outlet{
    color: var(--color-font-outlet);
    
}
/* justeringar för productList */
div.productList div.productPriceBuy div.productOptionPrice, 
div.productList div.productPriceBuy div.productOnePrice{
    width:auto;
}

div.productList div.productPriceBuy div.productBuyButtonWrapper,
div.productList div.productPriceBuy div.productOnePrice,
div.productList div.productPriceBuy div.productOptionPrice{
    float:left;
   


}


@media (max-width: 768px) {
    div.productGrid div.productPriceBuy div.productBuyButtonWrapper{
        width:100%;
        margin-left:0px;
        float:none;
    }

    div.productGrid div.productPriceBuy div.productBuyButtonWrapper div.productBuyButton{
        max-width: none;
    }
}

