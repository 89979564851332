div.help{
    background: grey;
    padding:20px;
    color:white;

}

div.help img{
    border-radius: 10px;
    max-width: 1160px !important;
    width:100% !important;
    height:auto !important;
    margin-bottom:150px;
}


@media (max-width: 768px) {
    div.help img{
        margin-bottom:50px;
    }
}