
div.header{
  background: var(--color-bg-header);
  position: sticky;
  width:100%;
  top: 0px;
  z-index:10;
  display:block;
  height:60px;
  
}



div.header a.logo{

  flex-grow: 0;
  flex-shrink: 1;
  background: transparent;


}

div.header a.logo img{
  max-width:300px;
  
  min-width:100px;
  margin:0px;
  width:calc(100% - 0px);
  float: left;
  margin-left:10px;

  
  
 }


div.header div.navigation{
  
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content:  space-between;
  align-items: center;
}


div.header div.headerOrder, 
div.header div.headerLogin, 
div.header div.headerReload{
  /*display:block;*/
  align-self: flex-end;
  flex-grow: 0;
  /*float:right;*/
  background: var(--color-header-button-login);
  
  height:40px;
  position: relative;
  border-radius: 10px;
  min-width: 40px;
  max-width: 200px;
  
  margin-right:10px;

  display: flex;
  align-items:center;
  
  overflow:hidden;
}

div.header div.headerOrder{
  min-width: 155px;
}

div.header div.headerOrder{
  background: var(--color-message-ok);
}

div.header div.headerOrder.alert{
  background: #d3460e;
}

div.header div.headerReload{
  background:var(--color-message-alert);
}


div.header div.headerOrder img.buttonOrder, div.header div.headerLogin img.buttonLogin, div.header img.buttonReload{
  max-width:24px;
  width:100%;
  height: auto;
  padding:0px;
  padding-left:8px;
  

  /*display:block; *//* TODO: FP: ta bort */
}



div.header div.headerOrder h1, div.header div.headerLogin h1, div.headerReload h1{
  color:var(--color-font-one-negative);
  margin:0px;
  display: inline;
  position: relative;
  /*top:5px;*/
  font-size: calc(0.3vw + 10px);
  padding-left:5px;
  padding-right:10px;
  min-width: 40px;

}

div.header div.headerOrder h1{
  min-width: 100px;
  text-align: center;
}

 div.header div.navigation div.buttonSideBar img{
  
  height:40px;
  display: none;
  
  padding-top:0px;
  padding-right:0px;
  padding-left:5px;
  
  flex-grow: 0;
  /*display:block; *//* TODO: FP: ta bort */
}

div.header a{
  color:var(--color-font-one);
  text-decoration: none;
}

div.header div.spinner{
  background-color: rgba(56, 56, 56, 1);
  /*background: rgb(255, 81, 0);*/

  background: var(--color-message-spinner);
  border-radius: 10px;
  margin:5px;
  margin-top:10px;
  float:right;
  padding:10px;
  padding-left:20px;
  padding-right:20px;
  font-weight: bold;
  position: absolute;
  right:0px;
  z-index: 10;
  color:var(--color-font-one-negative);

}

div.App div.layout div div.header div.toolbarProductType{
  background:transparent;
  flex-grow: 1;
  margin-left:10px;
  margin-right:10px;
  
}



div.App div.layout div div.header div.toolbarProductType a{
  background: var(--color-header-button-off);
  color:var(--color-font-header-button-off);
  padding:2px;
  padding-left:5px;
  padding-right:5px;
  border-radius:5px;
  margin-right:10px;
  font-size: calc(0.4vw + 14px);
}

div.App div.layout div div.header div.toolbarProductType a.selected{
  background: var(--color-header-button-on);
  color:var(--color-font-header-button-on);
}





div.header div.login{
  position: absolute;
  top:60px;
  right:0px;
  z-index:21; /* FP 2021-03 ökade denna till ett så den ska ligga över image */
  background: var(--color-bg-header);
  /*background: linear-gradient(10deg, rgb(0, 0, 3) 0%, rgb(59, 59, 59) 100%);*/
  width:50%;
  /*height:300px;*/
  display: block;
  border-radius: 0px 0px 10px 10px;
  overflow: hidden;
  box-shadow: 5px 5px 5px rgba(68, 68, 68, 0.6);
  padding:0px;
  border-top: 1px solid gray;

}

div.header div.login.loginVisible h1{
  color:var(--color-font-one-negative);
  padding-left:10px;
}


div.header div.login img.close{
  width:30px;
  height:auto;
  float:right;
  margin:10px;
}

div.header div.login.loginVisible{
  height: 400px;
  transition: height 300ms linear 0s;
}

div.header div.login.loginHidden{
  height: 0px;
  transition: height 300ms linear 0s;
}

div.header div.login div.loginMessage{
  background: transparent;
  margin:10px;
  width:calc(100% - 60px);
  border-radius: 10px;
  background: orange;
  font-weight:bold;
  /*background: linear-gradient(10deg, rgb(240, 6, 17) 0%, rgb(155, 4, 4) 100%);*/
  background: var( --color-message-alert);
 
}

div.header div.login.loginVisible div.info{
  background: var(--color-bg-dropmenu-searchfield);
  padding:10px;
  margin:10px;
  color:var(--color-font-one-negative);
  border-radius: 10px;
}

div.header div.login.loginVisible div.cookie{
  background: transparent;
  margin:5px;
}

div.header div.loginMessage.loginMessageVisible{
  height:23px;
  padding:10px;
  transition: height 300ms linear 0s;
  transition: padding 300ms linear 0s;
}

div.header div.loginMessage.loginMessageHidden{
  height: 0px;
  padding:0px;
  transition: height 300ms linear 0s;
  transition: padding 300ms linear 0s;
}


div.header div.errorMessage, 
div.header div.message,
div.header div.fullsizeImage{
  height:auto;
  position: absolute;
  top:60px;
  right:0px;
  z-index:20;
  background: var(--color-message-alert);
  /*background: linear-gradient(10deg, rgb(179, 5, 5) 0%, rgb(226, 24, 24) 100%);*/
  width:100%;

  
  display: block;
  border-radius: 0px 0px 10px 10px;
  overflow: hidden;
  box-shadow: 5px 5px 5px rgba(68, 68, 68, 0.6);
  transition: height 300ms linear 0s;
  color:var(--color-font-one-negative)
}

div.header div.message{
  background: var(--color-main-button-two);
}

div.header div.fullsizeImage{
  background: linear-gradient(10deg, rgb(0, 0, 3) 0%, rgb(59, 59, 59) 100%);
  
  overflow: scroll;
}

div.header div.errorMessageVisible,
div.header div.messageVisible{
  height: 80px;
  transition: height 300ms linear 0s;
}

div.header div.fullsizeImage.fullsizeImageVisible{
  background: rgba(0, 0, 0, 0.7);
  overflow: scroll;
  height:100vh;
  opacity: 1;
  transition: opacity 300ms linear 0s;
}
div.header div.fullsizeImage.fullsizeImageVisible img{
  max-width:100%;
}

div.header div.errorMessageHidden,
div.header div.message.messageHidden{
  height: 0px;
  transition: height 300ms linear 0s;
}

div.header div.fullsizeImage.fullsizeImageHidden{
  opacity: 0;
  height:0px;
}

div.header div.errorMessage div.close img,
div.header div.message div.close img,
div.header div.fullsizeImage div.close img{
    width:40px; 
    float:right;
    padding:10px;
}

div.header div.errorMessage h2,
div.header div.message h2, 
div.header div.fillsizeImage h2{
  padding-left:10px;
}

div.header div.errorMessage h2{
  float:left;
}

div.header div.errorMessage.errorMessageVisible h2.appendSession{

  background: black;
  padding-left:5px;
  padding-right:5px;
  border-radius: 5px;
  margin-left:10px;
  color:white;
}

div div.header div.fullsizeImage{
  text-align: center;
  max-width: calc(100%);
}

div.header div.fullsizeImage div.share{
  background: var(--color-main-button); 
  margin:0 auto;
  display: inline-flex;
  padding:10px;
  border-radius: 10px;
  max-width: 768px;

  align-items: center;

  
  
}

div.header div.fullsizeImage div.share div{
  background:transparent;
 

  white-space: nowrap;
  overflow: scroll;
  margin-left:10px;

}

div.header div.fullsizeImage div.share img{
  width:30px;
  height:30px;
  padding-left:0px;
}

div div.header div.fullsizeImage img.image{
  border-radius: 0px;
  padding-top:30px;
  padding-bottom:30px;
  max-width:1200px;
  max-width:90%;

}

div div.header div.fullsizeImage div.close{

  position:fixed;
  top:50px;
  max-width:1200px;
  width:100%;
  height:0px;
  
}

div div.header div.fullsizeImage.fullsizeImageHidden div.close{
  position:sticky;
  
  max-width:0px;
  width:0;
  height:0px;
  
}

div div.header div.fullsizeImage div.close img{
  width:30px;
  height:30px;
  cursor: pointer;
  margin-right:10px;
  margin-top:10px;
  float:right;
  
}


div.header div.login.login{
  color:var(--color-font-one-negative);
}
div.header div.login.login input, div.header div.login button{
  margin:10px;
  border:0px solid var(--color-font-one);
  background:var(--color-bg);
  color:var(--color-font-one);
  font-size: 20px;
  border-radius: 10px;
  padding:10px;


  width:calc(100% - 20px);
}

div.header div.login.login input[type='checkbox']{
  width:auto;
}

div.header div.login.login input{
  padding-left:10px;
  padding-right:10px;
  width:calc(100% - 40px);
  color:var(--color-font-one);
}

div.header div.login button{
  background: var(--color-main-button);
  color:var(--color-font-one-negative);
}

div.header div.login.cookie{
  color:var(--color-font-one-negative);
}

html body div#root div.App div.layout div.page div.header div.login div.testCookie{
  display:inline-block;
  border-radius: 10px;
  background: grey;
  margin-left:10px;
  padding:5px;
  color:white;
  
}



@media (max-width: 768px) {
  
  div.App div.layout div div.header div.toolbarProductType a{
    background: var(--color-header-button-off);
    color:var(--color-font-header-button-off);
    padding:0px;
    padding-left:3px;
    padding-right:3px;
    border-radius:4px;
    margin-right:0px;
    font-size: calc(1.0vw + 10px);
    
    
  }

  div.header div.login{
    width:100%;
  }

  div.header a.logo img{
    max-width:180px;
    margin-left:5px;
    
    
   }
  div.header div.navigation div.buttonSideBar img{
    display: block;
  }

  div.header div.fullsizeImage div.share{
    width:90%;
  }



}






@media (max-width: 408px) {
  
  div.App div.layout div div.header div.toolbarProductType a{
    background: var(--color-main-button);
    color:var(--color-font-one-negative);
    padding:0px;
    padding-left:1px;
    padding-right:2px;
    border-radius:4px;
    margin-right:0px;
    font-size: calc(1.0vw + 10px);
    
    
  }

  div.header div.navigation div.buttonSideBar img{
    width:100%;
    height:auto;
    max-width:40px;
    max-height:40px;
    padding-left:0px;

  }

  div.header a.logo img{
    max-width:140px;
    
    margin-left:5px;
    
    
   }

   div.header div.navigation div.right div.headerLogin img.buttonLogin, div.header div.navigation div.right div.headerReload img.buttonReload{
     display: none;
   }
   
   div.header div.navigation div.right div.headerLogin, div.header div.navigation div.right div.headerReload img.buttonReload{
     margin-right:0px;
     height:30px;
   }

   div.header div.navigation div.right div.headerLogin h1, div.header div.navigation div.right div.headerReload{
     padding-right:0px;
   }

   div.header div.headerOrder{
    min-width: auto;
    width:40px;
  }

  div.header div.headerOrder h1{
    display: none;
  }

}



.spinnerOrder, .spinnerProductDetails img, .spinnerProductReadMore  {

  /*margin: 100px auto;*/
  background: none; 
  -webkit-animation: sk-scaleout 1.0s infinite ease-in-out;
  animation: sk-scaleout 1.0s infinite ease-in-out;
}




@-webkit-keyframes sk-scaleout {
  0% { -webkit-transform: scale(0) }
  100% {
    -webkit-transform: scale(1.0);
    opacity: 0.1;
  }
}

@keyframes sk-scaleout {
  0% { 
    -webkit-transform: scale(0);
    transform: scale(0);
  } 100% {
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
    opacity: 0.5;
  }
}
