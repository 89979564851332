div.findBrandList{
  background: var(--color-bg);
}

div.findBrandList div.findBrandHeader h1{
    font-size: calc(1.5vw + 10px);
}

div.findBrandList div.findBrandHeader{
  background: var(--color-bg);
  padding:10px;
  display: flex;
  align-items: center;
  height:50px;
  position: sticky;
  top:60px;
  z-index: 8;
}

div.findBrandList div.findBrandHeader a{
  display: flex;
  align-items: center;

}

div.findBrandList div.findBrandHeader img{
  width:30px;
  height:30px;
  margin-right:10px;
}


div.findBrandList div.searchField{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width:calc(50% - 40px);
  background: var(--color-bg-main);
  padding:10px;
  margin:0px;
  margin-left:10px;
  margin-bottom:10px;
  
  border-radius: 10px;
  height:32px;
}

div.findBrandList div.searchField input{
  padding:5px;
  border:0px solid transparent;
  width:calc(100% - 50px);
  background: transparent;
  font-size:18px;
  color:var(--color-font-one);
}

div.findBrandList div.searchView{
  display: flex;

  background: var(--color-bg);
  width:100%;
  position: sticky;
  top:135px;
  padding-top:10px;
  z-index: 8;
  
}

div.findBrandList div.searchField img{
  width:30px;
  height:30px;
}


div.findBrandList div.button{
  display: inline-block;
  background: var(--color-main-button);
  border-radius: 10px;

  margin:0px;
  margin-left:10px;

  padding:10px;
  padding-right:20px;
  display: flex;
  justify-content: center;
  max-height: 30px;

  
}

div.findBrandList div.searchView div.button h3{
  color:var(--color-font-one-negative);
}

div.findBrandList div.button img{
  margin-right:10px;
  width:30px;
  height:30px;
}


div.findBrandList div.findBrandNameResult{
  margin-top:10px;
  padding:5px;
  margin-bottom:10px;
}

div.findBrandList div.findBrandNameResult div.brandName{
  float:left;
  background: var(--color-bg-brand-name-result);
  padding:5px;
  padding-left:10px;
  padding-right:10px;
  margin-left:5px;
  margin-bottom:5px;
  display: block;
  border-radius: 10px;
  color:var(--color-font-one-negative);

}
div.findBrandList div.findBrandNameResult div.brandName a,
div.findBrandList div.findBrandNameResult div.brandName a:hover{
  color:var(--color-font-one-negative);
  text-decoration:none;
}


div.findBrandList div.findBrandResult{
  clear:both;
  width:100%;
  background: var(--color-bg-main);
  display: inline-grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr) );
}


div.findBrandList div.findBrand{
  background: var(--color-bg);
  min-width:200px;
  /*height:300px;*/
  padding:10px;
  margin:10px;
  border-radius: 10px;
  max-width:400px;
  position: relative;
}

div.findBrandList div.findBrand div.findBrandAnchor{
  position: absolute; 
  top: -200px; 
  left: 0;

}


div.findBrandList div.findBrand h3{
  font-size: calc(0.6vw + 8px);
}



div.findBrandList div.findBrand h1{
  font-size: calc(0.8vw + 10px);
}
div.findBrandList div.findBrand div.footer{
  display: block;
  background: var(--color-main-button);
  bottom:10px;
  position: absolute;
  width:calc(100% - 20px);
  text-align: center;
  padding-top:5px;
  padding-bottom:5px;
  border-radius: 10px;
  color:var(--color-font-one-negative);
}

div.findBrandList div.findBrand div.footer h1{
  font-size: calc(0.5vw + 10px);
}

div.findBrandList div.findBrand div.imageView{
  background: var(--color-font-one);
  float:none;
  display: block;
  width:100%;
  position: relative;
  border-radius: 10px;
}

div.findBrandList div.findBrand div.imageView:after{
  content: "";
  display: block;
  padding-bottom:100%;
}

div.findBrandList div.findBrand div.imageWrapper{
  background: white;
  display: flex;
  height: 100%;
  width:100%;
  position: absolute;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}
div.findBrandList div.findBrandResult div.findBrand div.brandDetails{
  text-align: center;
  margin-bottom:55px;
}




div.findBrandList div.findBrandResult div.findBrand div.brandDetails img.type{
  width:36px;
  height:36px;
  padding-top:5px;
  padding-bottom:5px;

}

div.findBrandList div.findBrandResult div.findBrand div.brandDetails div.www{

  display: flex;
  justify-content: center;
  align-items: center;
  margin-top:10px;

}

div.findBrandList div.findBrandResult div.findBrand div.brandDetails div.www img{
  padding-left:5px;
  margin-top:2px;
  width:12px;
  height:12px;
}

div.findBrandList div.findBrandResult div.findBrand div.brandDetails h1{
  font-size: calc(1.5vw + 10px);
  color:var(--color-font-one);
  margin-top:10px;
}

div.findBrandList div.findBrandResult div.findBrand div.brandDetails h3{
  color:var(--color-font-two);
}

div.findBrandList div.findBrandResult div.findBrand div.brandDetails a{
  color:var(--color-font-one);
  text-decoration: none;
}



div.findBrandList div.searchView div.button.off{
  background: var(--color-main-button);
}



div.findBrandList div.searchView div.button.off h3{
  color:var(--color-font-two-negative);
}





@media (max-width: 768px) {
  div.findBrandList div.searchView{
    display: block;
    padding-bottom:10px;
    padding-top:10px;
    top:130px;

  }

  div.findBrandList div.searchField{
    width:calc(100% - 30px);
    margin-top:0px;
    padding:5px;
  }

  div.findBrandList div.button{
    float:left;
    margin:0px;
    margin-left:10px;
    margin-right:10px;
    padding:5px;
    padding-left:10px;
    padding-right:10px;

  }

  div.findBrandList div.findBrandNameResult div.brandName{
   
    padding:2px;
    padding-left:6px;
    padding-right:6px;
    margin-left:5px;
    margin-bottom:3px;
    border-radius: 5px;
  
  }









  
}