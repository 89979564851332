div#slideView div#modal{
	background:black;
	position: relative;
	max-width: 1200px;
	padding-top:0px;
	height:100%;
	pointer-events: none;
	text-align:center;
}

div#slideView div#modal img{
	width:30px;
	height:30px;
	margin-top:160px;
	    -webkit-animation:spin 1s linear infinite;
    -moz-animation:spin 1s linear infinite;
		animation:spin 1s linear infinite; 
		opacity: 0.3;
}

div#slideView div#controllers{
	background:transparent;
	position: relative;
	max-width: 1200px;
	padding-top:185px;
	pointer-events: none;
}

div#slideView div#controllers div#previous{
	float:left;
	pointer-events: auto; 
	cursor: pointer;

}



div#slideView div#controllers div#next{
	float:right;
	pointer-events: auto; 
cursor: pointer;
}

div#slideView div#controllers div img{
	height:30px;
	width:30px;
	padding-right:10px;
	padding-left:10px;
}


div#slideView div#slideList{
	background:black;
	height:400px;
	overflow:clip;
	max-width:1200px;
	background-image: url("/images/kolfiber.jpg");
}

div#slideView div#slideList div.slideWrapper{
	position: absolute;
	width:100%;
	height:400px;
	max-width:1200px;
	color:white;
	
}

div#slideView div#slideList div.slideWrapper.active{

	animation: animate-in 1s linear;
	animation-fill-mode: forwards;
	animation-delay: 0s;
}

div#slideView div#slideList div.slideWrapper.inactive{
		
		animation: animate-out 1s linear;
		animation-fill-mode: forwards;

}

@keyframes animate-out {
		
    0% {
 clip-path: inset(0 0 0 0);

    }
    100% {
			clip-path: inset(0 100% 0 0);

		
    }
}

@keyframes animate-in {
    0% {
			clip-path: inset(0 0 0 100%);

    }
    100% {
clip-path: inset(0 0 0 0);

				
    }
}





div#slideView div#footer div#dots{
	background:black;
	display:flex;
	justify-content: center;
	padding:10px;
	gap:10px;
	
}

div#slideView div#footer div#dots div{
	
	display:inline-block;
	width:12px;
	height:12px;
	border-radius: 7px;
	cursor: pointer;
}

div#slideView div#footer div#dots div.inactive{
background:var(--color-font-two);
}

div#slideView div#footer div#dots div.active{
background:white
}

div#slideView div#footer{
	display:flex;
	justify-content: space-between;
background:black;
color:white;
}

div#slideView div#footer div.pause{
	
	display:inline-block;
	width:12px;
	height:12px;
	border-radius: 7px;
	margin-top:10px;
	margin-right:5px;
}

div#slideView div#footer div.pause.off img{
	display:none;
}

div#slideView div#footer div.pause.on img{
	display:block;
}

div#slideView div#footer div.pause img{
	width:14px;
	height:14px;
}



/* slide */

div#slideView div#slideList div.slide{
	padding-left:50px;
	padding-right:50px;
	padding-top:20px;
	padding-bottom:20px;
	height: calc(400px - 40px);
}

div#slideView div#slideList div.slide div#desktop{
	display:flex;
	gap:10px;
	align-items:center;
}

div#slideView div#slideList div.slideWrapper div.slide div#desktop.image div.contentDesktopWrapper {
  width: 50%;
}


div#slideView div#slideList div.slide div#mobile{
	display:none;
}

div#slideView div#slideList div.slide div#desktop.image div.contentDesktopWrapper{
	flex-grow:1;
}

div#slideView div#slideList div.slide div.contentDesktopWrapper div.contentDesktop{
	height:calc(400px - 40px);
	display:flex;
	flex-direction:column;

}



div#slideView div#slideList div.slide div#desktop div img{
	/*width:100%;*/
	height:auto;
	max-height:360px;
	
}

div#slideView div#slideList div.slide div#mobile img{
	max-width:100%;
}

div#slideList div.slideWrapper div.slide div div#readMore{
	border-radius: 10px;
	cursor: pointer;
	background:var(--color-main-button-break);
	padding:5px;
	padding-left:10px;
	padding-right:10px;
	text-align:center;
}

div#slideList div.slideWrapper div.slide div div#readMore a{
	color:white;
	text-decoration: none;
}

div#slideList div.slideWrapper div.slide div.contentDesktop div#readMore{

	max-width: fit-content;
}

div#slideList div.slideWrapper div.slide div.image div.contentDesktop  div#readMore{
  /*max-width: none; ändrar bredden på knappen*/
  min-width: 100px;
}

div#slideList div.slideWrapper div.slide div#desktop div.contentDesktop p{
flex-grow:0;
overflow:scroll; 

margin:0px;
  padding-top: 0px;
  padding-bottom: 20px;

}

div#slideList div.slideWrapper div.slide div#mobile div.contentMobile{
flex-grow:1;overflow:scroll; 
}

div#slideList div.slideWrapper div.slide div#mobile div#readMore{
	margin-top:10px;
	text-align:center;	
}

div#slideList div.slideWrapper div.slide h1 {
	/*text-shadow:rgba(122, 121, 121, 0.9) 0px 0px 20px;*/
	
	filter: drop-shadow(0px 0px 7px rgba(0, 0, 0, 0.7));
  font-size: clamp(20px, calc(1.2vw + 20px), 40px);
}

div#slideList div.slideWrapper div.slide p {
	/*text-shadow: grey 0px 0px 10px;*/
	filter: drop-shadow(0px 0px 3px rgba(128, 128, 128, 0.9));
  font-size: clamp(14px, calc(1.2vw + 10px), 20px);
}

div#slideList div.slideWrapper div.slide.backgroundTint50 {
  background-color: rgba(0, 0, 0, 0.5);
  background-blend-mode: multiply;
}

div#slideList div.slideWrapper div.slide.backgroundTint25 {
  background-color: rgba(0, 0, 0, 0.25);
  background-blend-mode: multiply;
}

@media (max-width: 768px) {

	div#slideView div#slideList div.slide div#mobile{
		height:calc(400px - 30px);display:flex;flex-direction:column;

	}

	div#slideView div#slideList div.slide div#desktop{
		display:none;
	}
}




