
.stickyHeader{
    background: var(--color-bg-header-topmenu);
    position: -webkit-sticky;
    position: static;
    color:var(--color-font-one);
    padding:10px;
    height:50px;
    z-index:10;

}

div.stickyHeader input{
    font-size:16px;
}

div.sideMenu div.mcList, div.sideMenu div.mcTree{ /* begränsar höjden så listan i sidemenu inte ska bli för lång */
    height: calc(100vh / 2);
    overflow: scroll;

}

div.sideMenu.sideMenuVisible div div div h3{
    color:var(--color-font-one-negative);
}

div.sideMenu.sideMenuVisible div div.mcList div.listModell div,
div.sideMenu div.mcList div.listModell{
    background:var(--color-main-button); 
}

div.sideMenu div div.mcList div.listModell div.yearDivStyleBlock div.mcListYear{
    background:var(--color-main-button-two); 

}

div.sideMenu div.mcList{
    background:var(--color-bg);

}

div.sideMenu div.categoryTree{
    background:var(---color-bg-main);
}

div.sideMenu div.manufactorList{
    background:var(--color-bg-main);
}

div.sideMenu div.bikeTypeList{
    background:var(--color-bg-main);
}

div.sideMenu div.bikeTypeList div.bikeType {
    background: var(--color-main-button);
    margin:10px;
    

}


div.sideMenu div.filterResult div.showProducts{
    padding-left:5px;
    padding-right:5px;
    padding-top:5px;
}

div.sideMenu div.filterResult div.showProducts div{
    background: var(--color-main-button);
    padding:10px;
    border-radius: 10px;
    float:right;
    width:100px;
    height:50px;
    font-size:16px;
    text-align:right;
    color:var(--color-font-one-negative);
}

div.sideMenu div.filterResult div.showProducts div b{
    font-size:24px;
}

div.sideMenu div.filterResult{
    background: var(--color-bg);
    position: -webkit-sticky;
    position: sticky;
    color:var(--color-font-one);
    height:85px;
    z-index:11;
    /*overflow: scroll; FP: Tog bort denna för att fixa dropdown med årsmodeller*/ 
    /*overflow: scroll;*/
    overflow: visible;
}

div.sideMenu div.filterResult div.filters{
    background: var(--color-bg);
    padding:5px;
    margin:0px;
    height:60px;
    /*overflow: scroll;
    */
    

}

div.sideMenu div.filterResult div.filters div.filter{
    background: var(--color-main-button);
    color:var(--color-font-one-negative);
    display: inline-block;
    padding:3px;
    border-radius: 5px;
    padding-left:5px;
    padding-right:5px;
    margin-right:5px;
    font-size:calc(0.8vw + 8px);    
    overflow: hidden;
    max-height:20px;
    max-width:260px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap; 
    /*direction: rtl;*/
    text-align: left;
    min-height: 20px;

}

div.sideMenu div.filterResult div.filters div.filter.right{
    direction: rtl;
}

div.sideMenu div.filterResult div.filters div.filter img{
    width:12px;
    height:auto;
    position: relative;
    top:1px;
    padding-left:3px;
}

html body div#root div.App div.layout div div#sideMenu.sideMenu.sideMenuVisible div div.filterResult{
    background: var(--color-bg);
}

div.sideMenu div.stickyHeader div.search{
    background: var(--color-bg-main);
    padding:10px;
    border:0px solid white;
    border-radius: 10px;
}

div.sideMenu div.stickyHeader div.search img.closeButton, div.sideMenu div.stickyHeader div.search img.searchButton{
    width:25px;
    height: auto;
    float:right;
    margin-top:1px;
}

div.sideMenu div.stickyHeader div.search input{
    width: calc(100% - 50px);
    border:0px solid red;
    background:transparent;
    color:var(--color-font-one);
    height:25px;
    font-size:16px;
    margin:0px;
    padding:0px;

}

div.sideMenu  div.manufactorList{
    
    min-height: 30%;

}


div.sideMenu div div.manufactorList div div.manufactorListNode{
    background: var(--color-main-button);
    padding:5px;
    margin:5px;
    border-radius: 5px;
    float:left;
    padding-left: 10px;
    padding-right:10px;
    font-size:calc(0.7vw + 12px);

}


div.sideMenu div div.manufactorList div div.manufactorListNode.hasProduct{
    color:var(--color-font-one-negative);
}


div.sideMenu div div.manufactorList div div.manufactorListNode.noProduct{
    color:var(--color-font-two-negative);
}


div.sideMenu.sideMenuVisible div div.stickyHeader h3{
    color:var(--color-font-one);
    margin-top:10px;
}

div.sideMenu.sideMenuVisible div div.categoryList div.categoryListNode{
    background: var(--color-main-button);
    padding:10px;
    margin:10px;
    border-radius: 10px;
    color: var(--color-font-one-negative);
}

html body div#root div.App div.layout div div#sideMenu.sideMenu div div.filterResult div.filters div.filter.mc div.filterMcYearView{
  /*background: red;*/
    position: fixed;
    transform: translate3d(0, 0, 200px);
    /*top:85px;*/
    z-index:11;
   
}
div.sideMenu.sideMenuVisible div div.filterResult div.filters div.filter.mc div.filterMcYearView{
    
}