:root {



  
  --color-main-button-break: #ed1d24;
  
	--color-bg: rgb(233, 232, 232);
	--color-bg-hover: rgb(215, 215, 215);

	
  --color-bg-main: white;
  --color-bg-header: #2b2b2b;
  --color-bg-header-topmenu:#cccbcb;
  --color-bg-header-topmenu-button:#2b2b2b;
  --color-bg-header-topmenu-button-off:#2b2b2b;
  --color-bg-header-topmenu-button-on:var(--color-main-button-break);

  --color-bg-dropmenu-searchfield:#666666;
  --color-bg-dropmenu-searchfield-placeholder:#f7f7f7;
  --color-bg-dropmenu-resultcount:black;
  --color-bg-dropmenu-tree:#3d3d3d;
  --color-bg-dropmenu-list:black;
  

  --color-bg-product-list:#efeeee;

  --color-bg-start-footer: black;

  --color-header-button-on: white;
  --color-header-button-off: black;
  --color-header-button-login: var(--color-header-button-off);
  
  --color-order-selected: rgb(6, 148, 25);
  --color-order-sent-bar: grey;
  --color-order-textfield: rgb(233, 232, 232);

  --color-main-button: black;
  --color-main-button-two: grey;
  --color-main-button-outlet: black;
  
  --color-main-button-buy: black;
  --color-main-navigation-button-hover: black;

  --color-main-navigation-508: black;
  --color-main-navigation-bg-button-hover-508: #dfdbdb;

  --color-bg-reseller-letter: black;
  --color-bg-brand-name-result: #2b2b2b;

  --color-bg-categoryTreeNode-x: #3d3d3d;
  --color-bg-categoryTreeNode-xx: #666666;
  --color-bg-categoryTreeNode-xxx: #b8b7b7;
  
  --color-black: black;
  --color-unknown: transparent;
  

  --color-font-one: #2b2b2b;
  --color-font-two: #6d6c6c;

  --color-font-one-negative: white;
  --color-font-two-negative: #6d6c6c;
  --color-font-stock: white;
  --color-font-outlet: rgb(250, 92, 92); 
  --color-font-outlet-negative: rgb(250, 92, 92);

  --color-font-header-button-on: black;
  --color-font-header-button-off: white;
  

  --color-message: #666666;
  --color-message-warning: rgb(230, 92, 1);
  --color-message-alert: rgb(175, 10, 10);
  --color-message-ok: rgb(6, 148, 25);

  --color-message-spinner: rgb(247, 61, 14);
  --color-stock-ok: rgb(6, 148, 25);
  --color-stock-low: rgb(230, 92, 1);
  --color-stock-none: rgb(175, 10, 10);
	--color-stock-order: rgb(22, 109, 223);
	
		--color-message: rgb(22, 109, 223);
--color-message-ok: rgb(6, 148, 25);
--color-message-warning: rgb(230, 92, 1);
--color-message-error: rgb(175, 10, 10);

  /* darkmode */
    /*
  --color-bg: #181818;
  --color-bg-main: #3d3d3d;
  --color-bg-product-list:#efeeee;

  --color-font-one-negative: white;
  --color-font-two-negative: grey;

  --color-font-one: white;
  --color-font-two: grey;

*/
    /*--color-one: black;*/
  /*: #252525;*/
  /*--color-three: #3d3d3d;*/
  /*--color-four: #666666;*/
  /*--color-four: blue;*/
  /*--color-five: yellow;*/

}

html{
  height:100%;
}
html body{
  font-family: 'Open Sans', sans-serif;
  background:var(--color-bg);
  height:100%;
  margin:0;
  padding:0;
  /*background: url("gfx/bg-bike-mc.png") center center fixed;
  background-size: cover;
  */
}
textarea{
  font-family: 'Open Sans', sans-serif;
}

* {
  -webkit-overflow-scrolling: touch;
}

h1, h3{
  margin:0px;
  padding:0px;
}

#root{
  width:100%;
  max-width:1200px;
  background: var(--color-black);
  margin:auto;
  display: table;
height: 100%;
}
.App {
  background-color: var(--color-bg-main);
  color:var(--color-font-one);
  width:100%;
  height: 100%;
display: table-row;
  

  
}

div.clearBoth{
    display:block;
    clear:both;
    background:transparent;
    height:0px;
}
/*
div.header{
  background:var(--color-bg-header);
  position: sticky;
  width:100%;
  top: 0px;
  z-index:10;
  display:block;
  height:90px;
}*/

div.layout div.header h1{
  padding:5px;
}

.layout{
  width:100%;
  max-width:1200px;
  margin:auto 0;
  box-shadow: 0px 5px 5px 5px rgba(68, 68, 68, 0.3);
  height:100%;
  min-height:600px;

  
}


.sideMenu{
  top:50px;
  bottom:0px;
  padding-bottom:100px;
  background:var(--color-bg-main);
  height: calc(100% - 180px);
  position:fixed;
  overflow:scroll;
  z-index:10;
  opacity: 0.9; 
  display: none;
  /*display:block; *//* TODO: FP: ta bort */

}





.overlayVisible{
  background:var(--color-black);
  z-index:5;
  width:100%;
  height:100%;
  position:fixed;
  opacity: 0.8;
  display:none;
  top:0px;
}

.pointer{
  cursor: pointer;
}

@media (max-width: 768px) {
  .sideMenu{
      display: block;
  } 

  .overlayVisible{
    display:block;
  }
  
  
}

.sideMenuVisible{
  width: 80%;
  transition: width 300ms linear 0s;
}

.sideMenuHidden{
  width: 0%;
  transition: width 300ms linear 0s;
}

.desktop{
  display: block;
}


.mobile{
  display: none;
}

@media (max-width: 768px) {
  

  .sideMenu{
      display:block;
  }
  
  .desktop{
    display: none;
  }
  
  
  .mobile{
    display: block;
  }



  
}

div.displayNone{ /* div för att öka score */
  display:none;
  
}

div.displayBlock{
  display:block;
}




div.clearBoth{
  clear:both;
}

div.debug{
  background:lightblue;
  border-radius: 10px;
  padding:10px;
  margin:10px;
}



