.productGrid{
    border:0px solid transparent;
    border-radius: 10px;
    clear:both;
    display:grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr) );
    position: relative;
    top:0px;
    margin-left:0px;
    width:calc(100% - 0px);
    padding-bottom:50px;
}

.productGrid .product{

    
    min-height:100px;
    background: var(--color-bg);
    min-width:100px;
    padding:10px;
    margin:10px;
    border-radius: 10px;
    border:0px solid var(--color-font-one);
    max-width: 400px;
    /*max-height: 100%;*/
    position: relative;

}

div.productGrid div.imgbox{

    background:var(--color-bg);
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    border-radius: 10px;

    background-size: cover;
    
}



div.productGrid div.product div.productDetails{
    background: transparent;
    padding-top:10px;
    margin-bottom:55px;
}

div.productGrid div.product div.productDetails.buy{
    margin-bottom:80px;
}
.productGrid .product .productDetails .productName{
    background: transparent;
    /*height:85px;*/
    /*min-height:85px;*/
    overflow:hidden;
}

div.productGrid .product .productImage{
    
    width:100%;
    height:auto;
    border-radius: 10px;
    display: none;
}

div.productGrid div.product div.productDetails div div div.productBrand{
	color:white;
	background: var(--color-font-two-negative);
	border-radius: 5px;
	padding-left:3px;
	padding-right:3px;
	font-size: calc(0.5vw + 8px);
}

div.productGrid div.product div.productDetails div div div.productBrand:hover{
background:var(--color-main-button-break);
}


@media (max-width: 768px) {
    .productGrid{
        top:0px;
    } 

    .productGrid{
        
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr) );
        margin-left:5px;
        width:calc(100% - 10px);
        
    }

    .productGrid .product{
        padding:10px;
        margin:5px;
        border-radius: 11px;
		}
		div.productGrid div.product div.productDetails div div div.productBrand{
			display:none;
		}

}

/* FP: se productList-filen */