div.topMenu{

    background:var(--color-bg-header-topmenu);
    width:100%;
    display:block;
    position:sticky;
    height:100px;
    z-index:9;
    top:60px;
    display: flex;
  }

  div.topMenu, div.topMenu a{
    color:var(--color-font-one-negative);
  }

div.topMenu div.topMenuButton{

    background: var(--color-bg-header-topmenu-button);
    padding:10px;
    margin:10px;
    float:left;
    height:60px;
    width:calc(32% - 36px);
    border-radius:10px;
    flex: 1 1;
}


div.topMenu div.topMenuButton.open{
    background: var(--color-bg-header-topmenu-button-on);
}

div.topMenu div.topMenuButton:hover{
    background: var(--color-bg-header-topmenu-button-on);
    cursor: pointer;
}

div.topMenu div.topMenuButton.otherOpen{
    background: var(--color-bg-header-topmenu-button-off);
}
.topMenuButton img{
    width:26px;
    height:auto;
    margin-top:8px;
}

.dropMenuMC, .dropMenuCategory, .dropMenuManufactor, .dropMenuBikeType{
    background: transparent;
    position:fixed;
    z-index:10;
    height:500px;
    width:100%;
    max-width:1200px;
    display:block;
    margin-top:-10px;
    box-shadow: 0px 30px 20px -0px rgba(7, 6, 6, 0.6);
    
    
    
}


.dropMenuMC .dropMenuContent, 
.dropMenuCategory .dropMenuContent, 
.dropMenuManufactor .dropMenuContent,
.dropMenuBikeType .dropMenuContent {
    padding:0px;
    margin-top:10px;
    background:var(--color-bg-header-topmenu);
    height:500px;
    overflow: scroll;
    
}

.dropMenuMC .dropMenuContent h3, 
.dropMenuCategory .dropMenuContent h3, 
.dropMenuManufactor .dropMenuContent h3,
.dropMenuBikeType .dropMenuContent h3{
    color:var(--color-font-one-negative);
}

.dropMenuMC .dropMenuHeader, 
.dropMenuCategory .dropMenuHeader, 
.dropMenuManufactor .dropMenuHeader, 
.dropMenuBikeType .dropMenuHeader{
    background: var(--color-bg-header-topmenu);
    position: -webkit-sticky; 
    position: sticky;
    top: 0;
    padding:10px;


}

div.filterList{
    background:transparent;
    padding-left:12px;
    padding-top:10px;
    display:none;
}
div.filterList div.filter, div.topMenu div.filter{

    background: var(--color-main-button);
    color:var(--color-font-one-negative);
    display: inline-block;
    padding:5px;
    border-radius: 5px;
    padding-left:5px;
    padding-right:5px;
    margin-right:5px;
    margin-top:5px;
    font-size:calc(0.8vw + 8px);    
    overflow: hidden;
    max-height:20px;
    max-width:360px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap; 
    direction: ltr;
    text-align: left;
    min-height: 20px;

}

div.filterList div.filter.right, div.topMenu div.filter.right{
    direction: rtl;
}

html body div#root div.App div.layout div div.searchResult div.noResult.desktop div.filterList{ /* override för att visa filter på desktop om man inte får någon träff */
    display: flex;
    padding-left:0px;
    
}

html body div#root div.App div.layout div div.searchResult div.noResult.desktop div.filterList div.filter{
    max-height: 30px;
    margin-left:0px;
}
div.topMenu{
    white-space: pre-wrap;
}


div.topMenu div.filter{ /* för topmeny */
    max-width:calc(100% - 72px);
    max-height:28px;
    overflow: scroll;
    white-space: normal; 
    font-size:calc(0.4vw + 10px);   
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap; 
    direction: ltr;
    text-align: left; 
    padding-top:0px;
    background: var(--color-main-button);
    
    
}

div.topMenu div.filter.right{ /* för topmeny */
    
    direction: rtl;
    
}


div.topMenu div.filter.filterWide{ /* för topmeny */
    max-width:calc(100% - 10px); 
}


div.filter.mc div.filterMcYearView{
    position: absolute;
    z-index: 3;
    background: var(--color-main-button);
    
    width:80px;
    margin-left:2px;
    border-radius: 0 0 5px 5px;
}

div.filter.mc div.filterMcYearView div.year{
    
    padding:3px;
    margin:5px;
    text-align: center;
    border-radius: 10px;
}

div.filter.mc div.filterMcYearView div.year:hover{
    background: red;
}

div.topMenu div.filter.filterYear{
    
    margin-right:0px;
}

div.filterList div.filter img, div.topMenu div.filter img{
    width:12px;
    height:auto;
    position: relative;
    top:0px;
    padding-left:5px;
    padding-right:5px;
}


div.dropMenuHeader{
    display: flex;
    width:calc(100% - 20px);
  }

div.dropMenuContent div.dropMenuSearchResult{
    margin-left:10px;
    display: inline-block;
    background: var(--color-bg-dropmenu-resultcount);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding:10px;
    border-radius: 10px;
    width:20%;
    min-width: 250px;
    cursor:pointer;


}

div.dropMenuContent div.dropMenuSearchResult img{
    width:26px;
    height:26px;
    padding-left:10px;
}

div.dropMenuHeader div.searchField{
    background:var(--color-bg-dropmenu-searchfield);
    /*width:calc(50% - 15px);*/
    width:80%;
    padding:5px;
    margin:0px;
    border-radius: 10px;
    display: flex;
    height:40px;
    justify-content: space-between;
    align-items: center;
    
  }
  
  div.dropMenuHeader div.searchField input {
    font-size:20px;
    margin:5px;
    color:var(--color-font-one-negative);
    background: transparent;
    border:0px;
    width: calc(100% - 70px);
  }

  div.dropMenuHeader div.searchField input::placeholder {
    /*color:red;*/
    color:var(--color-bg-dropmenu-searchfield-placeholder);
  }
  
  div.dropMenuHeader div.searchField img {
    width:26px;
    height:26px;
    margin-right:5px;
  }

@media (max-width: 768px) {
    div.dropMenuMC, 
    div.dropMenuCategory, 
    div.dropMenuManufactor, 
    div.dropMenuBikeType{
        display:none;
    } 

    div.filterList{
        display:block;
    }

    div.topMenu{
        display:none;
    } 

    
}








