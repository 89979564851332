


div.productDetails div.productHeader .productName{
    color:var(--color-font-one);
    padding:0px;
}

div.productDetails div.product div div.productAdmin{
    background: #d54f0d;
    display: block;
    width:calc(100% - 20px);
    margin-top:10px;
    padding:10px;
    border-radius: 10px;
    margin-bottom:10px;
    color:white;
}

div.productDetails div.product div div.productAdmin a,
div.productDetails div.product div div.productAdmin a:hover,
div.productDetails div.product div div.productAdmin a:visited{
    color:white;
}

div.productDetails div.product div div.productAdmin table{
    width:100%;

}

div.productDetails div.product div div.productDetails div.productAdmin table tr td img{
    width:24px;
}

div.productDetails div.product div div.productDetails div.productAdmin table tr td{
    background:transparent;
    padding-top:10px;
    padding-right:10px;
    
}


div.header div.productDetails div.product div div.productDetails h3.manufactor{
    margin-left:5px;
    color:var(--color-font-one);
    padding:0px;
}

div.header div.productDetails div.product div div.productDetails h3.outlet{

    color:var(--color-font-outlet-negative);
}

div.productDetails div.product div div.productDetails div img{
    width:24px;
    height:24px;
    padding-right:0px;
    float:left;
  
}

div.productDetails div.product div div.productDetails div img.spinning{

    -webkit-animation:spin 1s linear infinite;
    -moz-animation:spin 1s linear infinite;
    animation:spin 1s linear infinite;   
}

@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }

div.productDetails div#product.product div div.productDetails div.detailsHeader{
    display: flex;
    cursor: pointer;
    align-items: center;
  
}

div.productDetails div.productHidden{
    height: 0%;
    background: var(--color-main-button);
    position: fixed;
    width:100%;
    top:60px;
    max-width: 1200px;
    transition: height 300ms linear 0s;
}



div.productDetails div.product{

    transition: height 300ms linear 0s;
    position: fixed;
    /*height:calc(100% - 200px);*/
    height:80%;
    top:60px;
    /*bottom:0px;*/

    width:calc(100% - 0px);
    max-width: 1200px;
    background:var(--color-bg-main);
    min-width:100px;
    margin-left:0px;
    border-radius: 0px 0px 10px 10px;
    overflow:scroll;

    box-shadow: 0px 30px 20px -0px rgba(7, 6, 6, 0.6);
}

div.productDetails div.product div div.productImages{
    background: var(--color-bg);
    display:block;
    width:calc(50% - 30px);
    min-height:300px;
    overflow:hidden;
    float:right;
    padding:10px;
    margin-right: 10px;
    margin-top:10px;
    border-radius: 10px;
    margin-bottom:10px;
}
div.productDetails div.product div div.productImages div.startImage{
    padding-left:0px;
    padding-right:0px;
    text-align: center;
    background:transparent;
    min-height:400px;
    padding-top:10px;
    
}

div.productDetails div.product div div.productImages div.startImage img{
    width:calc(100% - 20px);
    /*width:auto;*/
    
    border-radius: 10px;
    /*max-height:600px;*/
    


}

div.productDetails div.product div div.productImages div.imageList{
    padding:0px;
    background: transparent;
    display: flex;
    align-items: baseline;
    justify-content: center;
    max-height:150px;
    overflow-x: auto;
    overflow-y: hidden;
    
}

div.productDetails div.product div div.productImages div.imageList div.imageItem{
    padding:10px;
    background: transparent;
    
    
    
}

div.productDetails div.product div div.productImages div.imageList div.imageItem img{
    width:auto;
    border-radius: 5px;
    max-height:130px;
    
}

div.productDetails div.product div div.productDetails{
    background:transparent;
    display:block;
    width:calc(50% - 20px);
    min-height:100px;
    float:left;
    padding:10px;
    overflow: hidden;

}

div.productDetails div.product div div.productExtraInformation{
    background: var(--color-bg);
    margin:10px;
    padding:10px;
    min-height:300px;
    clear:both;
    border-radius: 10px;
}

div.productDetails div div.moreBox div.productExtraInformation div.categoryList div.category img,
div.productDetails div div.moreBox div.productExtraInformation div.mcList div.mc img{
    float:right;
    height:20px;
    width:auto;
}

div.productDetails div.product div div.closeBottom{
    position: sticky;
    bottom:0px;
    background: var(--color-main-button);
    height:80px;
    text-align: center;
    width:100%;
    margin:0 auto ;

    
    

}

div.productDetails div.product div div.closeBottom img{
    padding-top:20px;
    width:40px;
    
}



.productDetails .product .close{
    position: fixed;
    width:100%;
    max-width:1200px;
    background:transparent;
    height:10px;
    
    
   


}

.productDetails .product .close img.closeButton{
    width:36px;
    margin-right:10px;
    margin-top:10px;
    float:right;
    
}

div.productDetails div.imageView{
    position: fixed;
    max-width:1200px;
    background: grey;
    width:100%;
    height:100px;
}

div.header div.productDetails div.product div div.productDetails h1.productName{
    padding: 0px;
}

div.header div.productDetails div.product div div.productOption{
    background: var(--color-bg);
    min-height:200px;
    
    /*float:left;*/
    width:calc(100% - 20px);
    padding:10px;
    border-radius: 10px;
}

div.header div.productDetails div.product div div.productOption table{
    width:100%;
}

div.header div.productDetails div.product div div.productOption table tr.productOptionHeader td{
    background: var(--color-bg);
    font-weight: bold;

}

div.header div.productDetails div.product div div.productOption table tr{
    background:var(--color-bg-main);

}

div.header div.productDetails div.product div div.productOption table tr td{
    background: transparent;
}

div.header div.productDetails div.product div div.productOption table tr td{
    padding: 3px;
}

div.header div.productDetails div.product div div.productOption table tr td.outlet{
    color:var(--color-font-outlet-negative);
}

div.productDetails div.product div div.productExtraInformation div.description,
div.productDetails div.product div div.productExtraInformation div.connectedProductList,
div.productDetails div.product div div.productExtraInformation div.mcList,
div.productDetails div.product div div.productExtraInformation div.categoryList,
div.productDetails div.product div div.productExtraInformation div.itemList{
    background:var(--color-bg-main);
    padding:10px;
    border-radius: 10px;
    margin-bottom:10px;
}

div.productDetails div.product div div.productExtraInformation div.connectedProduct,
div.productDetails div.product div div.productExtraInformation div.mc,
div.productDetails div.product div div.productExtraInformation div.category,
div.productDetails div.product div div.productExtraInformation div.item
{
    background:var(--color-main-button);
    color:var(--color-font-one-negative);
    padding:10px;
    margin-left:0px;
    margin-top:10px;
    border-radius: 10px;
    cursor: pointer;
}

div.productDetails div.product div div.productExtraInformation div.item a{
    color:var(--color-font-one-negative);
}
div.productDetails div.product div div.productExtraInformation div.connectedProduct:hover,
div.productDetails div.product div div.productExtraInformation div.mc:hover,
div.productDetails div.product div div.productExtraInformation div.category:hover,
div.productDetails div.product div div.productExtraInformation div.item:hover{
    background: var(--color-main-button-break);

}


div.productDetails div.product div div.productExtraInformation div div img{
    float:right;
    height:20px;
    width:20px;
}
div.productDetails div div div.productExtraInformation div h3.header{
    display: inline;
}


div.productDetails div div div.productExtraInformation div img.copy{
    display: inline;
    height:20px;
    width:20px;
}

div.productDetails  div.productOption table tbody tr.productOptionRow td b.ean{
	color: var(--color-font-two-negative);
	font-size: calc( 0.3vw + 8px);
}

div.productDetails  div.productExtraInformation div.specification{
    
    margin-bottom:10px;
    background: var(--color-bg-main);
    
    padding:10px;
    border-radius: 10px;
} 

div#productResult.productResult div.productList div.product div.productDetails div div.moreBox div.productExtraInformation div.specification{
    background: var(--color-bg);
}
div.productDetails  div.productExtraInformation div.specification table.specification{
    width:100%;
   
    margin-top:10px;
    background: var(--color-bg);
    border-spacing: 5px;
    border-collapse: separate;
    border-radius: 5px;
}

div.productDetails  div.productExtraInformation div.specification  table.specification tbody tr td{
    vertical-align: top;
    border-radius: 5px;
    padding-left:5px;
    padding:5px;
    
   
    color: var(--color-font-one);
}

div.productDetails  div.productExtraInformation div.specification  table.specification tbody tr td.col1{
    
    color: var(--color-font-one);
    border-radius: 5px;
    padding-left:5px;
    width:30%;
    min-width:100px;
    font-weight: bold;
    background: var(--color-bg-main);
    

}



div.productDetails  div.productExtraInformation div.specification table.specification tbody tr td.col2{
    
    background: var(--color-bg-main);
    color: var(--color-font-one);

}
/*
.spinnerProductDetails{
    background: transparent;
    float: right;
    border-radius: 10px;
    padding-top:5px;
    margin-left:10px;
    right: 0px;
    opacity: 0.5;
    position: absolute;
    z-index:100;
    
    
}*/

/*.spinnerProductDetails img{
    width:40px;
}*/

div.productExtraInformation div.connectedProductList div.connectedProduct img{
    width:20px;
    height:20px;
    float:right;

}

div.productExtraInformation div.connectedProductList div.connectedProduct img.productImage{
    width:100%;
    height:auto;
    max-width: 200px;
    border-radius: 10px;
    float:left;
}

div.productExtraInformation div.connectedProductList div.connectedProduct div.information{
    padding:10px;
    background: transparent;
    display: inline-block;
}


div.productExtraInformation div.connectedProductList div.connectedProduct div.information h3{
    color:var(--color-font-one-negative);
}

div.productExtraInformation div.connectedProductList div.connectedProduct div.information h4{
    margin:0px;
    font-weight:bold;
    color:var(--color-font-two-negative);
}

div.productDetails div.product div div.productExtraInformation div.mcList h3{
    color:var(--color-font-one);
}


@media (max-width: 768px) {
  
    div.productExtraInformation div.connectedProductList div.connectedProduct img{
        width:20px;
        height:20px;
        float:none;
        display: none;
    }

    div.productExtraInformation div.connectedProductList div.connectedProduct img.productImage{
        width:100%;
        height:auto;
        max-width:calc(100% - 20px);
        border-radius: 10px;
        float:none;
        display: inline;
    }

    div.productDetails div.product div div.productImages{
        background: var(--color-main-button);
        display:block;
        width:calc(100% - 40px);
        min-height:300px;
        float:none;
        margin-right: 10px;
        margin-left: 10px;
    }

    div.productDetails div.product div div.productDetails{
        background:transparent;
        display:block;
        width:calc(100% - 20px);
        min-height:300px;
        float:none;
    }
}