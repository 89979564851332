
div.manufactorInfo{
    
    padding:10px;
    margin-left: 10px;
    margin-top:10px;
    width: calc(100% - 40px);
    background: var(--color-bg-header);
    border-radius:10px;


}
div.manufactorInfo div.information h3{
    float:none;
    color: var(--color-font-one-negative);
}

div.manufactorInfo div.information p{
    margin:0px;
    color: var(--color-font-one-negative);
}
div.manufactorInfo div.information{
    
    float:left;
    width:calc(100% - 100px);
    
    margin-left:10px;
    font-size: calc(0.5vw + 10px);
    
}

div.manufactorInfo div.information a{
    color: var(--color-font-one-negative);
    text-decoration: none;
    padding:0px;
    padding-left:5px;
    padding-right:5px;
    background: black;
    border-radius: 5px;
}

div.manufactorInfo div.information a:hover{
    background: red;
    
}

div.manufactorInfo div.imageWrapper{
    width: 80px;
    height:80px;
    float:left;
   
    display: flex;
    
    align-items: center;
    
    background: white;
    border-radius:10px;
}

div.manufactorInfo div.imageWrapper img{
    width:100%;
    height:auto;
    padding:2px;
}

div.manufactorInfo div.footer{
    clear:both;
}